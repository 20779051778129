import { StatusStyle } from './styles'
import React from 'react'
const InvoiceStatus = ({ status , size = "default"}) => {

  const invoiceStatusMap = {
    bn_created: "สร้างใบเรียกเก็บ",
    wait_confirm: "รอยืนยันยอด",
    confirmed: "ยืนยันยอด",
    sent: "รอรับชำระ",
    paid: "ชำระแล้ว",
    rv_created: "ชำระแล้ว",
    overdue: "เกินกำหนดชำระ",
    cancelled: "ยกเลิก",
    partially_paid: "ชำระแล้วบางส่วน",
  };

  return status ? <StatusStyle size={size} status={status}>{invoiceStatusMap[status]}</StatusStyle> : <></>
}

export default InvoiceStatus;
