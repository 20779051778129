import { StatusStyle } from './styles'
import React from 'react'
const ReceiptStatus = ({ status , size = "default"}) => {

  const invoiceStatusMap = {
    rv_created: "สร้างใบรับเงินแล้ว",
    paid: "ชำระแล้ว",
    cancelled: "ยกเลิก",
    partially_paid: "ชำระแล้วบางส่วน",
  };

  return status ? <StatusStyle size={size} status={status}>{invoiceStatusMap[status]}</StatusStyle> : <></>
}

export default ReceiptStatus;
