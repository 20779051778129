import React, { useState } from 'react'
import CustomTable from '../../../CustomTable'
import { Typography, Statistic } from 'antd'
import useAxios from 'axios-hooks'
import { CardListStyle, CardStyle } from '../style'
import currency from "currency.js";

const PaydayDetailTable = ({ invoice_group_id }) => {

  const { Paragraph } = Typography
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `/admins/api/invoice_groups/${invoice_group_id}/payday_details?page=${currentPage}&search=${search}`,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },

    }, {
      useCache: false,
    })

  const columns = [
    {
      title: 'ลำดับ',
      render: (text, record, index) => <a>{index+1}</a>,
    },
    {
      title: 'เลขบัตรประชาชน',
      dataIndex: 'citizen_id',
      key: 'citizen_id',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'เลขที่สัญญา',
      render: (text, _) => <a>{_.raw_data.loanId}</a>,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'firstname',
      key: 'firstname',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastname',
      key: 'lastname',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'สินเชื่อ',
      render: (text, _) => <a>{_.raw_data.productName}</a>,
    },
    {
      title: 'จำนวนเงินถอน',
      render: (text, _) => <a>{currency(_.raw_data.cashOut).format({ symbol: '', separator: ',', precision: 2 })}</a>,
    },
    {
      title: 'ค่าธรรมเนียมเบิกถอน',
      render: (text, _) => <a>{currency(_.raw_data.withdrawalFeeIncVat).format({ symbol: '', separator: ',', precision: 2 })}</a>,
    },
    {
      title: 'ค่าธรรมเนียมธนาคาร',
      render: (text, _) => <a>{currency(_.raw_data.bankFee).format({ symbol: '', separator: ',', precision: 2 })}</a>,
    },

  ]

  return <>
    <CardListStyle>
      <CardStyle>
        <Statistic title="รวมจำนวนเงินถอน" value={data ? data.total_cash_out : 0} precision={2}/>
      </CardStyle>
      <CardStyle>
        <Statistic title="รวมค่าธรรมเนียมเบิกถอน" value={data ? data.total_withdrawal_fee_inc_vat : 0} precision={2}/>
      </CardStyle>
      <CardStyle>
        <Statistic title="รวมค่าธรรมเนียมธนาคาร" value={data ? data.total_bank_fee : 0} precision={2}/>
      </CardStyle>
    </CardListStyle>
    <CustomTable searchPlaceholder={`ค้นหาโดย ชื่อ นามสกุล เลขบัตรประชาชน เลขที่สัญญา`} loading={loading}
                 columns={columns} data={data ? data.invoice_payday_details : []} total={data ? data.total_pages : 0}
                 currentPage={currentPage}
                 setCurrentPage={setCurrentPage} setSearch={setSearch}/>
  </>
}

export default PaydayDetailTable
