import React from 'react';
import PropTypes from 'prop-types';
import { flatMap, map, sortBy, groupBy, compact, flatten} from 'lodash';

import Select from 'react-select';

const PartnerFilter = ({ partners, selected = [], autoRefresh = true, name = 'partner_ids', isMulti = true, optionAll = false}) => {

  const groupedPartners = groupBy(sortBy(partners, 'label'), 'group')
  const options = Object.keys(groupedPartners).sort().map((group)=>(
    { label: `${group}`,
      options: compact([
        (isMulti ? {label: group, value: map(groupedPartners[group], 'value')} : null),
        ...groupedPartners[group]
      ])
    }
  ));

  if (optionAll) {
    options.unshift({
      label: 'All',
      options: [{ label: 'All', value: map(partners, 'value') }]
    })
  }

  const [selectedOptions, setSelectedOptions] = React.useState(
    partners.filter(
      ({ value }) => (selected.includes(value))
    )
  )

  const onChange = (val) => {
    let partner_ids
    if (isMulti){
      partner_ids = flatMap(val, 'value')

      setSelectedOptions(
        partners.filter(
          ({ value }) => (partner_ids.includes(value))
        )
      )
    } else {
      setSelectedOptions([val])
      partner_ids = val.value
    }

    const url = new URL(window.location.href)
    url.searchParams.set(name, partner_ids)

    if (autoRefresh) { window.location.replace(url) }
  }

  const formatGroupLabel = (data) => (
    <div>
      <span>{data.label}</span>
    </div>
  );

  return (
    <>
      <Select
        value={selectedOptions}
        onChange={onChange}
        options={flatten(options)}
        formatGroupLabel={formatGroupLabel}
        isMulti={isMulti}
        placeholder={'เลือกพาร์ทเนอร์'}
      />
      {name && <input type="hidden" name={name} value={selectedOptions.map(({value}) => value)} />}
    </>
  );
}

PartnerFilter.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
  selected: PropTypes.arrayOf(PropTypes.number),
  autoRefresh: PropTypes.bool,
  name: PropTypes.string,
  isMulti: PropTypes.bool,
  optionAll: PropTypes.bool,
}

export default PartnerFilter
