import React from 'react';
import PropTypes from 'prop-types';
import { Form, DatePicker, Button, Select, Checkbox, message, Typography, Radio } from 'antd';
import { jsonRequest } from '../../../helpers';
import _ from 'lodash';

const { Option } = Select;
const { Text } = Typography;

const ReportForm = ({ lenders , setIsModalOpen , refetch }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false)
  const [salaryDates, setSalaryDates] = React.useState([])
  const [employmentStatuses, setemploymentStatuses] = React.useState([])
  const [partners, setPartners] = React.useState([])
  const groupedPartners = _.groupBy(partners, 'partner_group')

  const onChange = () => {
    const { partner_ids, lender_id } = form.getFieldsValue(true)
    const partnerGroups = partner_ids.filter(ele=>_.isString(ele))

    if(partnerGroups.length > 0) {
      const ids = _.flatten(partnerGroups.map((group) => _.map(_.get(groupedPartners, group), 'id')))
      const new_partner_ids = _.uniq([...ids, ..._.difference(partner_ids, partnerGroups)])
      form.setFieldsValue({ partner_ids: new_partner_ids});
    }

    if (lender_id) {
      const lender = _.find(lenders, { id: lender_id });
      setPartners(_.get(lender, 'partners', []))
    }

    if (lender_id && (partner_ids[0] || partnerGroups.length > 0)) {
      setLoading(true)
      requestOptions()
    }
  };

  const resetPartners = () => {
    form.setFieldsValue({ partner_ids: []});
  }

  const resetOptions = () => {
    form.setFieldsValue({ salary_dates: [], employment_statuses: []});
  }

  const requestSubmit = async (values) => {
    try {
      const authenticity_token = document.head.querySelector(
        'meta[name="csrf-token"]'
      ).content;
      const response = await jsonRequest('POST', '/admins/invoice/invoice_group', { ...values, authenticity_token});

      if (response.status === 200) {
        message.success({ content: (<div>{'Report is request to lms'}</div>)  } );
      } else {
        message.error({ content: (<div>{'Something went wrong'}</div>) });
      }
      setIsModalOpen(false)

      await refetch()
    } catch (e) {

    }
  }

  const requestOptions = async () => {
    const { partner_ids, lender_id } = form.getFieldsValue(true)
    const response = await jsonRequest('GET', `/admins/invoice/invoice_group?partner_ids=${partner_ids}&lender_id=${lender_id}`);

    if (response.status === 200) {
      const json = await response.json();
      setSalaryDates(json.salary_dates)
      setemploymentStatuses(json.employment_statuses)
    }
    setLoading(false)
  }

  const onFinish = (values) => {
    if(values.report === 'seperate') {
      values.partner_ids.forEach((partner_id)=>{
        requestSubmit({...values, partner_ids: [partner_id]});
      })
    } else {
      requestSubmit(values);
    }
  };

  const filterOption = (input, option) => {
    if(!_.isString(option.children)) {
      const str = option.children.props.children[0]

      return str.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    return(option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  return (
    <Form form={form} name="control-hooks" onFinish={onFinish} labelCol={{span: 100}}
      initialValues={{ type: [], partner_ids: [], report: 'seperate' }}
    >
      <Form.Item name="lender_id" label="Lender" rules={[{ required: true }]}>
        <Select
          showSearch
          filterOption={filterOption}
          placeholder="Select a option and change input text above"
          onChange={() => {onChange(); resetPartners(); resetOptions();}}
          loading={loading}
        >
          {lenders.map(({ id, name }) => (
            <Option value={id} key={`lender-${id}`}>{name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="partner_ids" label="Partner" rules={[{ required: true }]}>
        <Select
          mode="multiple"
          showSearch
          filterOption={filterOption}
          placeholder="Select a option and change input text above"
          onChange={onChange}
          loading={loading}
        >
          { Object.keys(groupedPartners).sort().map((group, i) => {
            return(
              <React.Fragment key={`group-${group}`}>
                <Option value={group}>
                  <Text type="secondary" strong>{`${group || 'No name'} (group)`} </Text>
                </Option>
                {groupedPartners[group].map(({id, name, version}) => (
                  <Option value={id} key={`partner-${id}`}>{`${name} (version ${version})`}</Option>
                ))}
              </React.Fragment>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item name="salary_dates" label="Salary dates" rules={[{ required: true }]}>
        <Select
          showSearch
          mode="multiple"
          filterOption={filterOption}
          placeholder="Select a option and change input text above"
          loading={loading}
        >
          {salaryDates.map((val) => (
            <Option value={val} key={`salaryDate-${val}`}>{val}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="employment_statuses" label="Employment statuses" rules={[{ required: true }]}>
        <Select
          showSearch
          mode="multiple"
          filterOption={filterOption}
          placeholder="Select a option and change input text above"
          loading={loading}
        >
          {employmentStatuses.map((val) => (
            <Option value={val} key={`employmentStatus-${val}`}>{val}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="type" label="Type" rules={[{ required: true }]}>
        <Checkbox.Group >
          <Checkbox value="payday">Payday</Checkbox>
          <Checkbox value="other">Other</Checkbox>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item name="report" label="รวม report ตามบริษัทที่เลือก" rules={[{ required: true }]}>
        <Radio.Group>
          <Radio.Button value="seperate">แยก Report</Radio.Button>
          <Radio.Button value="append">รวม Report</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item  shouldUpdate>
        {
          () => { return (

            <>
              {
                form.getFieldValue('type').includes('payday') &&
                  <>
                    <Form.Item label="วันเริ่มเบิก PAYDAY" name="from_date" labelCol={{ span: 100 }} rules={[{ required: true }]}>
                      <DatePicker />
                    </Form.Item>
                    <Form.Item label="วันสิ้นสุดเบิก PAYDAY" name="to_date" labelCol={{ span: 100 }} rules={[{ required: true }]}>
                      <DatePicker />
                    </Form.Item>
                  </>
              }
              {
                form.getFieldValue('type').includes('other') &&
                  <Form.Item label="วันตัดรอบ" name="as_of_date" labelCol={{ span: 100 }} rules={[{ required: true }]}>
                    <DatePicker />
                  </Form.Item>
              }
            </>
          )
          }
        }
      </Form.Item>

      <Button type="primary" htmlType="submit" size="large">
        Submit
      </Button>
    </Form>
  );
}

ReportForm.propTypes = {
  lenders: PropTypes.arrayOf(PropTypes.PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    partners: PropTypes.arrayOf(PropTypes.PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      version: PropTypes.number,
      partner_group: PropTypes.string,
    })),
  })),
}

export default ReportForm
