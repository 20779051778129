import React, { useState, useRef } from 'react'
import Layout from '../../Layout'
import { Space, Button, Modal, Select, DatePicker, Input, message , Tabs } from 'antd'
import { TableStyle, SpaceFilter } from './style'
import Header from '../../Header'
import CustomTable from '../../CustomTable'
import ReceiptStatus from '../ReceiptStatus'
import useAxios from 'axios-hooks'
import { UploadOutlined, PlusOutlined, SendOutlined, MailOutlined } from '@ant-design/icons'
import { format, addMonths, startOfMonth , endOfMonth } from 'date-fns'
import moment from 'moment'
import ViewIcon from '../../../../assets/images/icons/ic_view.svg'
import currency from 'currency.js'
import PartnerDropdown from "../../PartnerDropdown";

const ReceiptList = ({ email: currentEmail, lender_list, partner_list }) => {

  const dateFormat = 'DD/MM/YYYY'
  const currentDate = new Date()
  const nextMonthDate = addMonths(currentDate, 1)

  const firstDateThisMonth = startOfMonth(currentDate);
  const lastDateThisMonth = endOfMonth(currentDate);
  const [rangeDateList, setRangeDateList] = useState([firstDateThisMonth, lastDateThisMonth])
  const email = useRef()
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [partiallyPaid, setPartiallyPaid] = useState('')
  const [fullyPaid, setFullyPaid] = useState('')
  const [statusDisplay, setStatusDisplay] = useState('')
  const [isModalStatusOpen, setIsModalStatusOpen] = useState(false)
  const [isModalReportOpen, setIsModalReportOpen] = useState(false)
  const [isModalEmailOpen, setIsModalEmailOpen] = useState(false)
  const [typeReport, setTypeReport] = useState('noburo')
  const [rangeDate, setRangeDate] = useState([currentDate, nextMonthDate])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { RangePicker } = DatePicker
  const [pageSize, setPageSize] = useState(20)
  const [lender, setLender] = useState("")
  const [partners, setPartners] = useState([])


  const showModalStatusOpen = () => {
    setIsModalStatusOpen(true)
  }

  const handleOkStatusOpen = async () => {
    try {
      await updateStatus({
        data: JSON.stringify({
          status, receipt_summary_ids: selectedRowKeys
        })
      })
      setSelectedRowKeys([])
      await refetch()
    } catch (e) {

    }
    setIsModalStatusOpen(false)
  }

  const handleCreateXlsx = async () => {
    try {
      await createXlsx({
        data: JSON.stringify({
          start_date: format(rangeDate[0], 'yyyy-MM-dd'),
          end_date: format(rangeDate[1], 'yyyy-MM-dd'),
          type: typeReport,
        })
      })

      message.success({
        content: 'สร้างรายงานสำเร็จ คลิ๊กที่เพื่ือตรวจสอบไฟล์ และ ดาวน์โหลด',
        duration: 5,
        onClick: () => {
          window.open(`/admins/requested_files`)
        }
      })
      await refetch()
    } catch (e) {
      console.log(e)
    }
    setIsModalReportOpen(false)
  }

  const handleCanceStatusOpen = () => {
    setIsModalStatusOpen(false)
  }

  const handleChangeSelectStatus = (value, raw) => {
    setStatus(raw.id)
  }

  const [{ loading: loadingUpdateStatus, error: errorUpdateStatus }, updateStatus] = useAxios({
    url: `/admins/api/receipt_summaries/update`, method: 'POST', headers: {
      'Content-Type': 'application/json'
    },
  }, {
    manual: true
  })

  const [{ loading: loadingSendEmail, error: errorSendEmail }, sendEmail] = useAxios({
    url: `/admins/api/receipt_summaries/send_email`, method: 'POST', headers: {
      'Content-Type': 'application/json'
    },
  }, {
    manual: true
  })

  const [{ loading: loadingCreateXlsx, error: errorCreateXlsx }, createXlsx] = useAxios({
    url: `/admins/api/receipt_summaries/create_xlsx`, method: 'POST', headers: {
      'Content-Type': 'application/json'
    },
  }, {
    manual: true
  })

  const optionSelectStatus = [{
    id: 2, value: 'cancelled', label: 'ยกเลิก',
  }]

  const optionSelectReport = [{
    id: 1, value: 'noburo', label: 'noburo',
  }, {
    id: 2, value: 'lender', label: 'Lender',
  }]

  const columns = [{
    title: 'เลขที่เอกสาร', dataIndex: 'tax_id', key: 'tax_id', width: 140, render: (item) => <a>{item}</a>,
  }, {
    title: 'ผู้ให้สินเชื่อ', dataIndex: 'lender_name', key: 'lender', render: (item) => <a>{item}</a>,
  }, {
    title: 'พาร์ทเนอร์', dataIndex: 'partner_name', key: 'partner', render: (item) => <a>{item}</a>,
  }, {
    title: 'วันที่สร้าง',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (date) => <a>{format(new Date(date), 'yyyy-MM-dd')}</a>,
  }, {
    title: 'ยอดหักเงินเดือน',
    dataIndex: 'salary_deduction',
    key: 'salary_deduction',
    render: (item) => <a>{currency(item / 100).format({ symbol: '', separator: ',', precision: 2 })}</a>,
  }, {
    title: 'โอนให้สถานบันการเงิน',
    dataIndex: 'transfer_ittp',
    key: 'transfer_ittp',
    render: (item) => <a>{currency(item / 100).format({ symbol: '', separator: ',', precision: 2 })}</a>,
  }, {
    title: 'โอนให้ noburo',
    dataIndex: 'transfer_noburo',
    key: 'transfer_noburo',
    render: (item) => <a>{currency(item / 100).format({ symbol: '', separator: ',', precision: 2 })}</a>,
  }, {
    title: 'เข้าบัญชีสถาบันการเงิน', dataIndex: 'lender_received', key: 'lender_received', render: (item) => <a>
      {currency(item).format({ symbol: '', separator: ',', precision: 2 })}
    </a>,
  }, {
    title: 'เข้าบัญชี noburo', dataIndex: 'noburo_received', key: 'noburo_received', render: (item) => <a>
      {currency(item).format({ symbol: '', separator: ',', precision: 2 })}
    </a>,
  }, {
    title: 'ส่วนต่างสถาบันการเงิน', render: (item, record) => <a>
      {currency(record.noburo_received - (record.transfer_noburo/100)).format({ symbol: '', separator: ',', precision: 2 })}
    </a>,
  }, {
    title: 'ส่วนต่าง noburo', render: (item, record) => <a>
      {currency(record.lender_received - (record.transfer_ittp/100)).format({ symbol: '', separator: ',', precision: 2 })}
    </a>,
  }, {
    title: 'สถานะ', key: 'action', render: (item, record) => (<Space size="middle">
      <ReceiptStatus status={item.status === 'paid' ? record.partially_paid ? 'partially_paid' : item.status : item.status}/>
    </Space>),
  }, {
    title: 'Action', key: 'action', render: (item, record) => (<Space size="middle">
      <a href={`/admins/receipt/receipt_summaries/${item.id}`}>
        <img src={ViewIcon} width={24} height={24}/>
      </a>
    </Space>),
  },]

  const partnerList = partners.length !== 0 ? partners.map((partner) => partner.value).join(',') : ''
  const url = `/admins/api/receipt_summaries?start_date=${format(rangeDateList[0], 'yyyy-MM-dd')}&end_date=${format(rangeDateList[1], 'yyyy-MM-dd')}&page=${currentPage}&search=${search}${statusDisplay && `&status=${statusDisplay}`}${partiallyPaid && `&partially_paid=${partiallyPaid}`}${fullyPaid && `&fully_paid=${fullyPaid}`}${lender && `&lender=${lender}`}${partnerList && `&partner_ids=${partnerList}`}`
  const [{ data, loading, error }, refetch] = useAxios({
    url: url,
    method: 'GET',
    headers: {
      'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0',
    },

  }, {
    useCache: false,
  })

  const onChange = (key) => {
    setSelectedRowKeys([]);
    setCurrentPage(1);
    switch (key) {
      case "1":
        setStatusDisplay("");
        setPartiallyPaid("");
        break;
      case "2":
        setStatusDisplay('paid');
        setPartiallyPaid('true')
        setFullyPaid('')
        break;
      case "3":
        setStatusDisplay('paid');
        setPartiallyPaid('')
        setFullyPaid('true')
        break;
      case "4":
        setStatusDisplay('cancelled');
        setPartiallyPaid('')
        break;
      default:
        console.log('Invalid key');
        break;
    }
  };

  const handleSendEmail = async () => {

    try {
      await sendEmail({
        data: JSON.stringify({
          email: email.current.input.value, ids: selectedRowKeys
        })
      })

      console.log(email.current.input.value)
      setIsModalEmailOpen(false)
    } catch (e) {

    }
  }

  const items = [
    {
      key: '1',
      label: `ทั้งหมด (${data ? data.count.partially_paid +  data.count.paid + data.count.cancelled : 0})`,
      children:  <CustomTable selectedRowKeys={selectedRowKeys}
                              pageSize={pageSize}
                              setPageSize={setPageSize}
                              setSelectedRowKeys={setSelectedRowKeys} loading={loading} columns={columns}
                              data={data ? data.receipt_summaries : []} total={data ? data.total_pages : 0.}
                              currentPage={currentPage}
                              searchPlaceholder="ค้นหาโดย เลขที่เอกสาร เลขที่ใบเรียกเก็บ ผู้ให้สินเชื่อ พาร์ทเนอร์"
                              setCurrentPage={setCurrentPage} setSearch={setSearch}>
        <SpaceFilter>
          <Select
              style={{
                width: "300px",
                height: "35px"
              }}
              defaultValue={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"}]}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder="เลือกผู้ให้สินเชื่อ"
              onChange={(select) => setLender(select)}
              options={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"} , ...lender_list]}/>
          <RangePicker
              style={{
                width: 280,
              }}
              size={`middle`}
              format={dateFormat}
              defaultValue={[moment(firstDateThisMonth), moment(lastDateThisMonth)]}
              onChange={(event) => setRangeDateList([event[0]._d, event[1]._d])}
          />
        </SpaceFilter>
        <PartnerDropdown partners={partner_list} setPartners={setPartners} mt={`8px`} />
      </CustomTable>,
    },
    {
      key: '2',
      label: `ชำระแล้วบางส่วน (${data ? data.count.partially_paid : 0})`,
      children:  <CustomTable selectedRowKeys={selectedRowKeys}
                              pageSize={pageSize}
                              setPageSize={setPageSize}
                              setSelectedRowKeys={setSelectedRowKeys} loading={loading} columns={columns}
                              data={data ? data.receipt_summaries : []} total={data ? data.total_pages : 0.}
                              currentPage={currentPage}
                              searchPlaceholder="ค้นหาโดย เลขที่เอกสาร เลขที่ใบเรียกเก็บ ผู้ให้สินเชื่อ พาร์ทเนอร์ สถานะ"
                              setCurrentPage={setCurrentPage} setSearch={setSearch}>
        <SpaceFilter>
          <Select
              style={{
                width: "300px",
                height: "35px"
              }}
              defaultValue={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"}]}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder="เลือกผู้ให้สินเชื่อ"
              onChange={(select) => setLender(select)}
              options={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"} , ...lender_list]}/>
          <RangePicker
              style={{
                width: 280,
              }}
              size={`middle`}
              format={dateFormat}
              defaultValue={[moment(firstDateThisMonth), moment(lastDateThisMonth)]}
              onChange={(event) => setRangeDateList([event[0]._d, event[1]._d])}
          />
        </SpaceFilter>
        <PartnerDropdown partners={partner_list} setPartners={setPartners} mt={`8px`} />
      </CustomTable>,
    },
    {
      key: '3',
      label: `ชำระแล้ว (${data ? data.count.paid : 0})`,
      children:  <CustomTable selectedRowKeys={selectedRowKeys}
                              pageSize={pageSize}
                              setPageSize={setPageSize}
                              setSelectedRowKeys={setSelectedRowKeys} loading={loading} columns={columns}
                              data={data ? data.receipt_summaries : []} total={data ? data.total_pages : 0.}
                              currentPage={currentPage}
                              searchPlaceholder="ค้นหาโดย เลขที่เอกสาร เลขที่ใบเรียกเก็บ ผู้ให้สินเชื่อ พาร์ทเนอร์ สถานะ"
                              setCurrentPage={setCurrentPage} setSearch={setSearch}>
        <SpaceFilter>
          <Select
              style={{
                width: "300px",
                height: "35px"
              }}
              defaultValue={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"}]}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder="เลือกผู้ให้สินเชื่อ"
              onChange={(select) => setLender(select)}
              options={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"} , ...lender_list]}/>
          <RangePicker
              style={{
                width: 280,
              }}
              size={`middle`}
              format={dateFormat}
              defaultValue={[moment(firstDateThisMonth), moment(lastDateThisMonth)]}
              onChange={(event) => setRangeDateList([event[0]._d, event[1]._d])}
          />
        </SpaceFilter>
        <PartnerDropdown partners={partner_list} setPartners={setPartners} mt={`8px`} />
      </CustomTable>,
    },
    {
      key: '4',
      label: `ยกเลิก (${data ? data.count.cancelled : 0})`,
      children:  <CustomTable selectedRowKeys={selectedRowKeys}
                              pageSize={pageSize}
                              setPageSize={setPageSize}
                              setSelectedRowKeys={setSelectedRowKeys} loading={loading} columns={columns}
                              data={data ? data.receipt_summaries : []} total={data ? data.total_pages : 0.}
                              currentPage={currentPage}
                              searchPlaceholder="ค้นหาโดย เลขที่เอกสาร เลขที่ใบเรียกเก็บ ผู้ให้สินเชื่อ พาร์ทเนอร์"
                              setCurrentPage={setCurrentPage} setSearch={setSearch}>
        <SpaceFilter>
          <Select
              style={{
                width: "300px",
                height: "35px"
              }}
              defaultValue={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"}]}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder="เลือกผู้ให้สินเชื่อ"
              onChange={(select) => setLender(select)}
              options={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"} , ...lender_list]}/>
          <RangePicker
              style={{
                width: 280,
              }}
              size={`middle`}
              format={dateFormat}
              defaultValue={[moment(firstDateThisMonth), moment(lastDateThisMonth)]}
              onChange={(event) => setRangeDateList([event[0]._d, event[1]._d])}
          />
        </SpaceFilter>
        <PartnerDropdown partners={partner_list} setPartners={setPartners} mt={`8px`} />
      </CustomTable>,
    }
  ]

  return (<Layout>
    <Header title={`ใบรับเงิน`}>
      <Space size="small">
        <Button type="primary" size={`large`} disabled={selectedRowKeys.length === 0}
                onClick={() => setIsModalEmailOpen(true)} icon={<SendOutlined/>}>
          ส่งอีเมล
        </Button>
        <Button onClick={showModalStatusOpen} type="secondary" size={`large`}
                disabled={selectedRowKeys.length === 0}>เปลี่ยนสถานะ</Button>
        <Button type="primary" size={`large`} onClick={() => setIsModalReportOpen(true)} icon={<PlusOutlined/>}>
          สร้างรายงาน
        </Button>
        <Button href={`/admins/receipt/receipt_upload`} type="primary" size={`large`} icon={<UploadOutlined/>}>
          อัพโหลดใบรับเงิน
        </Button>
      </Space>
    </Header>
    <TableStyle>
      <Tabs type="card" size="large" defaultActiveKey="1" items={items} onChange={onChange}/>
    </TableStyle>


    <Modal title="เปลี่ยนสถานะ" open={isModalStatusOpen} footer={null} onCancel={handleCanceStatusOpen}>
      <Select
        style={{
          width: 240,
        }}
        size="large" placeholder="เลือกสถานะ"
        onChange={handleChangeSelectStatus} options={optionSelectStatus}/>

      <Button
        size={`large`}
        style={{ marginTop: '10px' }}
        type="primary"
        loading={loadingUpdateStatus}
        onClick={handleOkStatusOpen}
      >
        ตกลง
      </Button>
    </Modal>

    <Modal title="สร้างรายงาน" open={isModalReportOpen} footer={null} onCancel={() => setIsModalReportOpen(false)}>
      <div>ประเภทรายงาน:</div>
      <Select
        style={{
          width: 280,
          marginBottom: '10px',
          marginTop: '10px',
        }}
        defaultValue={{
          id: 1, value: 'noburo', label: 'noburo',
        }}
        size="large" placeholder="ประเภทรายงาน"
        onChange={(select) => setTypeReport(select)} options={optionSelectReport}/>
      <div>วันที่:</div>
      <RangePicker
        style={{
          width: 280,
          marginBottom: '10px',
          marginTop: '10px',
        }}
        size={`large`}
        format={dateFormat}
        defaultValue={[moment(currentDate), moment(nextMonthDate)]}
        onChange={(event) => setRangeDate([event[0]._d, event[1]._d])}
      />

      <Button
        size={`large`}
        style={{ marginTop: '10px' }}
        type="primary"
        loading={loadingCreateXlsx}
        onClick={handleCreateXlsx}
      >
        ตกลง
      </Button>
    </Modal>

    <Modal title="ส่งอีเมล" open={isModalEmailOpen} footer={null} onCancel={() => setIsModalEmailOpen(false)}>

      <Input size="large" defaultValue={currentEmail} ref={email} placeholder="e-mail" prefix={<MailOutlined/>}/>
      <Button
        size={`large`}
        style={{ marginTop: '10px' }}
        type="primary"
        loading={loadingSendEmail}
        onClick={handleSendEmail}
      >
        ส่งอีเมล
      </Button>
    </Modal>
  </Layout>)
}

export default ReceiptList
