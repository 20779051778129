import React from 'react'
import {ChildStyle, Container, PaginationStyle, SearchStyle} from './style'
import { Input, Table, Pagination } from 'antd'

const CustomTable = (props) => {

  const {
    children,
    columns,
    data,
    loading,
    total,
    currentPage,
    setCurrentPage,
    setSearch,
    setSelectedRowKeys,
    selectedRowKeys,
    setPageSize = (e) => { console.log(e)},
    pageSize = 20,
    searchPlaceholder = 'ค้นหา...',
  } = props

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = []
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false
            }
            return true
          })
          setSelectedRowKeys(newSelectedRowKeys)
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = []
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true
            }
            return false
          })
          setSelectedRowKeys(newSelectedRowKeys)
        },
      },
    ],
  }

  const { Search } = Input

  const onSearch = (value, _e, info) => setSearch(value)

  const onChangePage = (page) => {
    setCurrentPage(page)
  }


  const configTable = selectedRowKeys ? {
    rowSelection: rowSelection,
    loading: loading,
    columns: columns,
    dataSource: data,
    pagination: {
      position: ['none', 'none'],
      pageSize: pageSize,
    }
  } : {
    loading: loading,
    columns: columns,
    dataSource: data,
    pagination: {
      position: ['none', 'none'],
      pageSize: pageSize,
    }
  }

  return <Container>
    <SearchStyle>
      <Search
        size={'large'}
        placeholder={searchPlaceholder}
        onSearch={onSearch}
        style={{
          width: 400,
        }}
      />
      <ChildStyle>{children}</ChildStyle>
    </SearchStyle>
    <Table {...configTable} />
    <PaginationStyle>
      <Pagination defaultCurrent={currentPage}
                  pageSize={pageSize}
                  total={pageSize * total}
                  onChange={onChangePage}
                  showSizeChanger={true}
                  onShowSizeChange={(current, size) => setPageSize(size)}
      />
    </PaginationStyle>
  </Container>
}

export default CustomTable
