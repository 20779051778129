import styled, { css } from 'styled-components'

export const TableStyle = styled.div`
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 8px 20px;
`

export const EditStyle = styled.div`

`

export const TabStyle = styled.div`
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 20px;
`
export const SearchStyle = styled.div`
    margin: 0 0 16px 0;
    display: flex;
    justify-content: flex-end;
`

export const CardStyle = styled.div`
    border: 1px solid #e8e8e8;
    border-left: 5px solid #1890ff;
    border-radius: 8px;
    padding: 16px 24px 10px 20px;
    margin-left: 8px;
`

export const CardListStyle = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 16px 0;
`
