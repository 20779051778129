import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

const options = [
  {
    label: 'มีแผน', value: 'have_plan'
  },
  {
    label: 'ไม่มีแผน', value: 'no_plan'
  },
  {
    label: 'ไม่สนใจแผน', value: 'ignore_plan'
  },
]

const DebtPlanFilter = ({name}) => {
  const [value, setValue] = React.useState('ignore_plan');

  const onChange = ({ target: { value } }) => {
    setValue(value);
  };

  return(
    <>
      <Radio.Group options={options} optionType="button" buttonStyle="solid" size="large" onChange={onChange} value={value}/>
      {name && <input type="hidden" name={name} value={value} />}
    </>
  )
}

DebtPlanFilter.propTypes = {
  name: PropTypes.string,
}

export default DebtPlanFilter
