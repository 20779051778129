import React from 'react'

const QuizButton = ({ children, active, onClick }) => {
  return (
    <button type="button" className={`btn btn-outline-${active ? 'primary' : 'secondary'} h-100 w-100`} onClick={onClick}>
      {children}
    </button>
  )
}

export default QuizButton
