import React from 'react'

import DestroyItem from '../DestroyItem'

const TrueFalseQuiz = ({ quizzes }) => {
  const [size, setSize] = React.useState(quizzes.length || 1)

  return (
    <React.Fragment>
      <div className="my-2">
        ให้ผู้เข้าร่วมเลือกถูกหน้าข้อความที่เห็นว่า
        <b> ถูก </b>
        และเลือกตัวเลือก
        <b> ผิด </b>
        หน้าข้อความที่เห็นว่าผิด
      </div>

      <Items quizzes={quizzes} size={size} />

      <div className="row form-group">
        <div className="col-6">
          <button type="button" className="btn btn-outline-primary btn-block" onClick={() => setSize(size + 1)}>
            <i className="fas fa-plus-circle" />
            {' เพิ่มคำถาม'}
          </button>
        </div>
        <div className="col-6">
          <button type="button" className="btn btn-outline-danger btn-block" onClick={() => setSize(size - 1 || 1)}>
            <i className="fas fa-minus-circle" />
            {' ลดคำถาม'}
          </button>
        </div>
      </div>
    </React.Fragment >
  );
}

const Items = ({ quizzes, size }) => {
  const items = [];
  const formSize = Math.max(quizzes.length, size);

  for (let i = 0; i < formSize; i++) {
    if (i < size){
      items.push(<Item index={i} {...quizzes[i]} key={`true-false-${i}`} />);
    } else {
      items.push(<DestroyItem index={i} id={quizzes[i].id} key={`true-false-${i}`}/>);
    }
  }

  return (<React.Fragment>{items}</React.Fragment>);
}

const Item = ({id, index, no, title, extra={}}) => {
  const correct_answer = extra.correct_answer

  return (<div className="row" >
    <input type="hidden" value={id} name={`task[quizzes_attributes][${index}][id]`} />
    <input type="hidden" value='TrueFalseQuiz' name={`task[quizzes_attributes][${index}][type]`} />
    <input type="hidden" value={no || index + 1} name={`task[quizzes_attributes][${index}][no]`} />
    <div className="col-8">
      <div className="form-group">
        <label>คำถามที่ {no || index + 1}</label>
        <input type='text' className='form-control' name={`task[quizzes_attributes][${index}][title]`} defaultValue={title} />
      </div>
    </div>
    <div className="col-4">
      <label>คำตอบ</label>
      <select className="form-control" name={`task[quizzes_attributes][${index}][correct_answer]`} defaultValue={correct_answer}>
        <option value='true'>ถูก</option>
        <option value='false'>ผิด</option>
      </select>
    </div>
  </div>
  )
}

export default TrueFalseQuiz
