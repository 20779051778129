import React from 'react'
import { Container, HeaderStyle } from './style'

const Header = (props) => {
  const { title, children } = props

  return <>
    <Container>
      <HeaderStyle>
        <h1>{title}</h1>
        <div>{children}</div>
      </HeaderStyle>
      <hr className="header"/>
    </Container>
  </>
}

export default Header
