import React, { useState, useRef } from 'react'
import Layout from '../../Layout'
import { Space, Button, Modal, Select, DatePicker, Input } from 'antd'
import { TableStyle } from './style'
import Header from '../../Header'
import CustomTable from '../../CustomTable'
import ReceiptStatus from '../ReceiptStatus'
import useAxios from 'axios-hooks'
import { UploadOutlined, PlusOutlined, SendOutlined, MailOutlined } from '@ant-design/icons'
import { format, addMonths } from 'date-fns'
import moment from 'moment'
import ViewIcon from '../../../../assets/images/icons/ic_view.svg'

const ReceiptSummaries = ({ receipt_group_id }) => {

  const email = useRef()
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [isModalStatusOpen, setIsModalStatusOpen] = useState(false)
  const [isModalEmailOpen, setIsModalEmailOpen] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const showModalStatusOpen = () => {
    setIsModalStatusOpen(true)
  }

  const handleOkStatusOpen = async () => {
    try {
      await updateStatus({
        data: JSON.stringify({
          status,
          receipt_summary_ids: selectedRowKeys
        })
      })
      setSelectedRowKeys([])
      await refetch()
    } catch (e) {

    }
    setIsModalStatusOpen(false)
  }

  const handleCanceStatusOpen = () => {
    setIsModalStatusOpen(false)
  }

  const handleChangeSelect = (value, raw) => {
    setStatus(raw.id)
  }

  const [{ loading: loadingUpdateStatus, error: errorUpdateStatus }, updateStatus] = useAxios({
    url: `/admins/api/receipt_summaries/update`, method: 'POST', headers: {
      'Content-Type': 'application/json'
    },
  }, {
    manual: true
  })

  const [{ loading: loadingSendEmail, error: errorSendEmail }, sendEmail] = useAxios({
    url: `/admins/api/receipt_summaries/send_email`, method: 'POST', headers: {
      'Content-Type': 'application/json'
    },
  }, {
    manual: true
  })

  const optionSelect = [
    {
      id: 1,
      value: 'paid',
      label: 'ชำระแล้ว',
    },
    {
      id: 2,
      value: 'cancelled',
      label: 'ยกเลิก',
    }
  ]

  const columns = [{
    title: 'เลขที่ใบเสร็จ', dataIndex: 'tax_id', key: 'tax_id', width: 140, render: (text) => <a>{text}</a>,
  }, {
    title: 'กลุ่มใบรับเงิน', dataIndex: 'code_group', key: 'code_group', render: (text) => <a>{text}</a>,
  }, {
    title: 'ยอดหักเงินเดือน',
    dataIndex: 'salary_deduction',
    key: 'salary_deduction',
    render: (text) => <a>{text / 100}</a>,
  }, {
    title: 'โอนให้สถานบันการเงิน',
    dataIndex: 'transfer_ittp',
    key: 'transfer_ittp',
    render: (text) => <a>{text / 100}</a>,
  }, {
    title: 'โอนให้ Noburo',
    dataIndex: 'transfer_noburo',
    key: 'transfer_noburo',
    render: (text) => <a>{text / 100}</a>,
  }, {
    title: 'พาร์ทเนอร์', dataIndex: 'partner_name', key: 'partner', render: (text) => <a>{text}</a>,
  }, {
    title: 'วันที่สร้าง',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (date) => <a>{format(new Date(date), 'MM/dd/yyyy')}</a>,
  }, {
    title: 'สถานะ', key: 'action', render: (_, record) => (<Space size="middle">
      <ReceiptStatus status={_.status}/>
    </Space>),
  }, {
    title: 'Action', key: 'action', render: (_, record) => (<Space size="middle">
      <a href={`/admins/receipt/receipt_summaries/${_.id}`}>
        <img src={ViewIcon} width={24} height={24}/>
      </a>
    </Space>),
  },]

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `/admins/api/receipt_summaries/${receipt_group_id}?page=${currentPage}&search=${search}`,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },

    }, {
      useCache: false,
    })

  const handleSendEmail = async () => {
    try {
      await sendEmail({
        data: JSON.stringify({
          email: email.current.input.value,
          ids: selectedRowKeys
        })
      })
      setIsModalEmailOpen(false)
    } catch (e) {

    }
  }

  return (<Layout>
    <Header title={`ใบรับเงิน`}>
      <Space size="small">
        <Button type="primary" size={`large`} disabled={selectedRowKeys.length === 0}
                onClick={() => setIsModalEmailOpen(true)} icon={<SendOutlined/>}>
          ส่งอีเมล
        </Button>
        <Button onClick={showModalStatusOpen} type="secondary" size={`large`}
                disabled={selectedRowKeys.length === 0}>เปลี่ยนสถานะ</Button>
      </Space>
    </Header>
    <TableStyle>
      <CustomTable selectedRowKeys={selectedRowKeys}
                   setSelectedRowKeys={setSelectedRowKeys} loading={loading} columns={columns}
                   data={data ? data.receipt_summaries : []} total={data ? data.total_pages : 0.}
                   currentPage={currentPage}
                   setCurrentPage={setCurrentPage} setSearch={setSearch}/>
    </TableStyle>


    <Modal title="เปลี่ยนสถานะ" open={isModalStatusOpen} footer={null} onCancel={handleCanceStatusOpen}>
      <Select
        style={{
          width: 240,
        }}
        size="large" placeholder="เลือกสถานะ"
        onChange={handleChangeSelect} options={optionSelect}/>

      <Button
        size={`large`}
        style={{ marginTop: '10px' }}
        type="primary"
        loading={loadingUpdateStatus}
        onClick={handleOkStatusOpen}
      >
        ตกลง
      </Button>
    </Modal>
    <Modal title="เปลี่ยนสถานะ" open={isModalStatusOpen} footer={null} onCancel={handleCanceStatusOpen}>
      <Select
        style={{
          width: 240,
        }}
        size="large" placeholder="เลือกสถานะ"
        onChange={handleChangeSelect} options={optionSelect}/>

      <Button
        size={`large`}
        style={{ marginTop: '10px' }}
        type="primary"
        loading={loadingUpdateStatus}
        onClick={handleOkStatusOpen}
      >
        ตกลง
      </Button>
    </Modal>


    <Modal title="ส่งอีเมล" open={isModalEmailOpen} footer={null} onCancel={() => setIsModalEmailOpen(false)}>

      <Input size="large" ref={email} placeholder="กรอกอีเมล" prefix={<MailOutlined/>}/>
      <Button
        size={`large`}
        style={{ marginTop: '10px' }}
        type="primary"
        loading={loadingSendEmail}
        onClick={handleSendEmail}
      >
        ส่งอีเมล
      </Button>
    </Modal>
  </Layout>)
}

export default ReceiptSummaries
