import _ from 'lodash';

const jsonRequest = (method, url, params = {}) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

  return _.isEmpty(params) ? fetch(url, { method, headers }) : fetch(url, { method, headers, body: JSON.stringify(params) });
}

export { jsonRequest }
