import React, { useState } from 'react'
import { Tabs } from 'antd'
import { TabStyle } from './style'
import SummaryTable from './SummaryTable'
import PaydaySummaryTable from './PaydaySummaryTable'
import PaydayDetailTable from './PaydayDetailTable'
import OtherSummaryTable from './OtherSummaryTable'
import OtherDetailTable from './OtherDetailTable'
import useAxios from 'axios-hooks'

const InvoiceSummaryNotResign = ({ invoice_group_id, lender, partner, setLenderReceived,setNoburoReceived }) => {

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `/admins/api/invoice_groups/${invoice_group_id}/summaries?page=${currentPage}&search=${search}`,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },

    }, {
      useCache: false,
    })

  const onChange = async (key) => {
    if(key === "1"){
      await refetch()
    }
  }

  const items = [
    {
      key: '1',
      label: 'สรุปรายการหักเงินเดือน',
      children: <SummaryTable invoice_group_id={invoice_group_id}
                              setLenderReceived={setLenderReceived}
                              setNoburoReceived={setNoburoReceived}
                              data={data}
                              loading={loading}
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                              setSearch={setSearch}/>,
    },
    {
      key: '4',
      label: 'ยอดหักสินเชื่อ',
      children: <OtherSummaryTable invoice_group_id={invoice_group_id}/>,
    },
    {
      key: '5',
      label: 'รายละเอียดสินเชื่อ',
      children: <OtherDetailTable invoice_group_id={invoice_group_id}/>
    },
    {
      key: '2',
      label: 'ยอดหัก PAYDAY',
      children: <PaydaySummaryTable invoice_group_id={invoice_group_id}/>,
    },
    {
      key: '3',
      label: 'รายละเอียด PAYDAY',
      children: <PaydayDetailTable invoice_group_id={invoice_group_id}/>,
    },

  ]

  return (<TabStyle>
    <Tabs type="card" size="large" defaultActiveKey="1" items={items} onChange={onChange}/>
  </TabStyle>)
}

export default InvoiceSummaryNotResign
