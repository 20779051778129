import React from 'react'
import PropTypes from 'prop-types'

import QuizButton from './QuizButton'
import TrueFalseQuiz from './TrueFalseQuiz'
import MultipleAnswersQuiz from './MultipleAnswersQuiz'
import SingleAnswerQuiz from './SingleAnswerQuiz'
import UploadImagesQuiz from './UploadImagesQuiz'
import WritingQuiz from './WritingQuiz'
import WebViewQuiz from './WebViewQuiz'

const QuizForm = ({csrf, url, quizzes, quiz_type}) => {
  const [selectedIndex, setIndex] = React.useState(
    {
      TrueFalseQuiz: 1,
      MultipleAnswersQuiz: 2,
      SingleAnswerQuiz: 3,
      UploadImagesQuiz: 4,
      WritingQuiz: 5,
      WebViewQuiz: 6
    }[quiz_type] || 1
  )

  return (
    <React.Fragment>
      <div className="row text-center">
        <div className="col-4 py-1" >
          <QuizButton active={selectedIndex === 1} onClick={() => setIndex(1)}>
            <i className="fas fa-check" />
            <br />
          เลือกถูกหรือผิด
          </QuizButton>
        </div>
        <div className="col-4 py-1">
          <QuizButton active={selectedIndex === 2} onClick={() => setIndex(2)}>
            <i className="fas fa-check-square" />
            <br />
          ตอบได้มากกว่าหนึ่งคำตอบ
          </QuizButton>
        </div>
        <div className="col-4 py-1">
          <QuizButton active={selectedIndex === 3} onClick={() => setIndex(3)}>
            <i className="fas fa-dot-circle" />
            <br />
          ตอบได้แค่คำตอบเดียว
          </QuizButton>
        </div>
        <div className="col-4 py-1">
          <QuizButton active={selectedIndex === 4} onClick={() => setIndex(4)}>
            <i className="fas fa-image" />
            <br />
           อัพโหลดรูปภาพ
          </QuizButton>
        </div>
        <div className="col-4 py-1">
          <QuizButton active={selectedIndex === 5} onClick={() => setIndex(5)}>
            <i className="fas fa-file-alt" />
            <br />
          บรรยายหรืออธิบาย
          </QuizButton>
        </div>
        <div className="col-4 py-1">
          <QuizButton active={selectedIndex === 6} onClick={() => setIndex(6)}>
            <i className="fas fa-globe" />
            <br />
            WEB View
          </QuizButton>
        </div>
      </div>
      <form encType="multipart/form-data" action={url} acceptCharset="UTF-8" method="post">
        <input type="hidden" name="_method" value="patch" />
        <input type="hidden" name="authenticity_token" value={csrf} />
        <Quiz index={selectedIndex} quizzes={quizzes} />
        {selectedIndex !== 6 && <input type="submit" name="commit" value="แก้ไข" className="btn btn-primary btn-block" data-disable-with="แก้ไข" />}
      </form>

    </React.Fragment>
  )
}

const Quiz = ({ index, quizzes }) => {
  if (index === 1) return <TrueFalseQuiz quizzes={quizzes} />;
  if (index === 2) return <MultipleAnswersQuiz quizzes={quizzes} />;
  if (index === 3) return <SingleAnswerQuiz quizzes={quizzes} />;
  if (index === 4) return <UploadImagesQuiz quizzes={quizzes} />;
  if (index === 5) return <WritingQuiz quizzes={quizzes} />;
  if (index === 6) return <WebViewQuiz quizzes={quizzes} />;
  return <TrueFalseQuiz quizzes={quizzes} />
}

export default QuizForm
