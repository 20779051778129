import React from 'react'
import Choice from './Choice'

const SingleAnswerQuiz = ({ id, title, quizType, setQuizType, index, extra, _destroy, no }) => {

  const [choices, setChoices] = React.useState(extra?.choices ?? ['', '', ''])

  const addChoice = () => { setChoices([...choices, '']) }
  const removeChoice = () => {
    if(choices.length < 3) return
    setChoices(choices.slice(0,-1))
  }

  const onChangeQuizType = (event) => {
    const quizType = event.target.value
    setQuizType(quizType)
  }

  if (_destroy) {
    return (
      <>
        <input type="hidden" value={_destroy} name={`task_v2[quiz_v2s_attributes][${index}][_destroy]`} />
        <input type="hidden" value={id} name={`task_v2[quiz_v2s_attributes][${index}][id]`} />
      </>
    )
  }

  return (
    <div className="card mt-3">
      <div className="card-body bg-light">
        <div className="row">
          <div className="col-8">
            <h5>
              {`คำถามที่ ${no}`}
            </h5>

            <input type="hidden" value={id} name={`task_v2[quiz_v2s_attributes][${index}][id]`} />
            <input type="hidden" value={no} name={`task_v2[quiz_v2s_attributes][${index}][no]`} />
            <textarea name={`task_v2[quiz_v2s_attributes][${index}][title]`} className='form-control' defaultValue={title} />
          </div>

          <div className="col-4">
            <label>ประเภทคำถาม</label>
            <select className="form-control" name={`task_v2[quiz_v2s_attributes][${index}][quiz_type]`} defaultValue={quizType} onChange={onChangeQuizType}>
              <option value='TrueFalseQuiz'>เลือกถูกหรือผิด</option>
              <option value='MultipleAnswersQuiz'>ตอบได้มากกว่าหนึ่งคำตอบ</option>
              <option value='SingleAnswerQuiz'>ตอบได้แค่คำตอบเดียว</option>
              <option value='UploadImagesQuiz'>อัปโหลดรูปภาพ</option>
              <option value='WritingQuiz'>บรรยายหรืออธิบาย</option>
              <option value='WebViewQuiz'>Webview</option>
              <option value='ApplyProductQuiz'>สมัครสินเชื่อ</option>
            </select>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <button type="button" className="btn btn-outline-primary" onClick={addChoice}>
              <i className="fas fa-plus" />
            </button>

            <button type="button" className="btn btn-outline-danger" onClick={removeChoice}>
              <i className="fas fa-minus" />
            </button>
          </div>
        </div>

        {
          choices.map((_, inx) => <Choice choices={choices} extra={extra} index={index} choiceInx={inx} key={`${inx}-${choices[inx]}`}/>)
        }

        <div className="row mt-3">
          <div className="col-8">
            <label className="mr-3">อธิบายคำตอบ</label>
            <textarea className='form-control'
              name={`task_v2[quiz_v2s_attributes][${index}][extra][explain]`}
              defaultValue={extra?.explain}
            />
          </div>
          <div className="col-4">
            <div className="form-check mt-5">
              <label className="form-check-label">
                <input type="checkbox" className="form-check-input"
                  name={`task_v2[quiz_v2s_attributes][${index}][extra][always_correct]`}
                  defaultChecked={extra?.always_correct}
                />
                Always correct
              </label>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default SingleAnswerQuiz
