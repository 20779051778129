import React from 'react'

const Choice = ({ choices, extra, index, choiceInx }) => {

  const choiceNo = choiceInx+1

  return (
    <div className="row mt-3">
      <div className="col-8">
        <label>คำตอบที่ {choiceNo}</label>
        <input type='text' className='form-control'
          name={`task_v2[quiz_v2s_attributes][${index}][extra][choices][]`}
          defaultValue={choices[choiceInx]}
        />
      </div>

      <div className="col-4">
        <div className="form-check mt-5">
          <label className="form-check-label">
            <input type="radio" className="form-check-input"
              name={`task_v2[quiz_v2s_attributes][${index}][extra][ans][]`}
              value={choiceNo}
              defaultChecked={extra?.ans?.includes(`${choiceNo}`)} />
            ถูก
          </label>
        </div>
      </div>
    </div>
  )
}

export default Choice
