import React, { useState, useRef } from 'react'
import Layout from '../../Layout'
import { Tabs, Button, Space, Modal, Input, Alert, DatePicker, Typography, Select, Card, message, Col, Row } from 'antd'
import Header from '../../Header'
import InvoiceSummaryNotResign from '../InvoiceSummaryNotResign'
import InvoiceSummaryResigned from '../InvoiceSummaryResigned'
import { EditOutlined, SendOutlined, CloseSquareFilled, FileOutlined } from '@ant-design/icons'
import useAxios from 'axios-hooks'
import InvoiceStatus from '../InvoiceStatus'
import { StatusBar } from './style'
import moment from 'moment'
import { format } from 'date-fns'

const InvoicePreview = ({ invoice_group, lender, partner, html_lender, html_noburo, overdue, email: currentEmail }) => {

  const dateFormat = 'DD/MM/YYYY'
  const { Title, Text } = Typography
  const [isModalEmail, setIsModalEmail] = useState(false)
  const [isModalDate, setIsModalDate] = useState(false)
  const [dueDate, setDueDate] = useState('')
  const [reportDate, setReportDate] = useState(new Date())
  const [transferDate, setTransferDate] = useState(new Date())
  const [transferDateNoburo, setTransferDateNoburo] = useState(new Date())
  const emailRef = useRef('')

  const [lenderReceived, setLenderReceived] = useState(0)
  const [noburoReceived, setNoburoReceived] = useState(0)
  const [isModalStatusOpen, setIsModalStatusOpen] = useState(false)
  const [status, setStatus] = useState('')
  const [statusView, setStatusView] = useState(invoice_group.status)

  const invoice_group_id = invoice_group.id

  const [{ loading: loadingUpdateStatus, error: errorUpdateStatus }, updateStatus] = useAxios({
    url: `/admins/api/invoice_groups/update`, method: 'PATCH', headers: {
      'Content-Type': 'application/json'
    },
  }, {
    manual: true
  })

  const [{ loading, error }, sendEmail] = useAxios({
    url: `/admins/api/invoice_groups/${invoice_group_id}/send_email`, method: 'POST', headers: {
      'Content-Type': 'application/json'
    },
  }, {
    manual: true
  })

  const showModalStatusOpen = () => {
    setIsModalStatusOpen(true)
  }

  const handleCanceStatusOpen = () => {
    setIsModalStatusOpen(false)
  }
  const handleChangeSelect = (value) => {
    setStatus(value)
  }

  const handleOkStatusOpen = async () => {
    try {
      if(status === 'rv_created') {
        if (lenderReceived <= 0 || noburoReceived <= 0) {
          message.error('กรุณากรอกยอดเงินที่ได้รับ')
          return false
        }
      }

      await updateStatus({
        data: JSON.stringify({
          status,
          invoice_group_ids: [invoice_group.id],
          report_date: format(reportDate, 'yyyy-MM-dd'),
          transfer_date: format(transferDate, 'yyyy-MM-dd'),
          transfer_date_noburo: format(transferDateNoburo, 'yyyy-MM-dd'),
          lender_received: +lenderReceived,
          noburo_received: +noburoReceived,
        })
      })

      setStatusView(status)

      status === 'rv_created' && message.success({
        content: 'สร้างใบรับเงินเรียบร้อย คลิ๊กเพื่อตรวจสอบ',
        duration: 5,
        onClick: () => {
          window.open(`/admins/receipt/receipt_summaries`)
        }
      })

      await refetch()
    } catch (e) {

    }
    setIsModalStatusOpen(false)
  }

  const optionSelect = [
    {
      id: 0,
      value: 'bn_created',
      label: 'สร้างใบเรียกเก็บแล้ว',
    },
    {
      id: 3,
      value: 'sent',
      label: 'รอรับชำระ',
    },
    {
      id: 5,
      value: 'cancelled',
      label: 'ยกเลิก',
    }
  ]

  const confirmSendEmail = async () => {
    try {
      await sendEmail({
        data: JSON.stringify({
          email: emailRef.current.input.value
        })
      })
      setIsModalEmail(false)
    } catch (e) {
      console.log(e)
    }
  }

  const onChangeDate = (date, dateString) => {
    setDueDate(dateString);
  }


  const confirmDate = async () => {
    await updateStatus({
      data: JSON.stringify({
        due_date: dueDate,
        invoice_group_ids: [invoice_group.id],
      })
    })
    setIsModalDate(false)
  }


  const onChange = (key) => {
    console.log(key)
  }

  const onChangePreview = (key) => {
    console.log(key)
  }

  const items = [{
    key: '1',
    label: 'Not Resign',
    children: <InvoiceSummaryNotResign lender={lender} partner={partner} invoice_group_id={invoice_group_id}
                                       setLenderReceived={setLenderReceived} setNoburoReceived={setNoburoReceived}/>,
  }, {
    key: '2',
    label: 'Resigned',
    children: <InvoiceSummaryResigned lender={lender} partner={partner} invoice_group_id={invoice_group_id}
                                      setLenderReceived={setLenderReceived} setNoburoReceived={setNoburoReceived}/>
  },]

  const itemsPdf = [{
    key: '1',
    label: 'Lender',
    children: <iframe className="iframe-render-html" srcdoc={`
    <style>
      p[style="page-break-after:always;"] {
          margin: 64px 0;
          height: 2px;
          background: #EEE;
          width: 100%;
      }
    </style>
    ${html_lender}`}></iframe>,
  }, {
    key: '2',
    label: 'Noburo',
    children: <iframe className="iframe-render-html" srcdoc={`
    <style>
      p[style="page-break-after:always;"] {
          margin: 64px 0;
          height: 2px;
          background: #EEE;
          width: 100%;
      }
    </style>
    ${html_noburo}`}></iframe>,
  },]

  return (<Layout>
    <StatusBar>
      <InvoiceStatus status={statusView === "sent" ? (overdue ? 'overdue': statusView): statusView} size={`large`}/>
    </StatusBar>
    <Header title={`ใบเรียกเก็บเงินแทน : ${invoice_group.no} `}>
      <Space size="small">
        <Button type="primary" size={`large`} icon={<SendOutlined/>} onClick={() => setIsModalEmail(true)}>
          ส่งอีเมล
        </Button>
        <Button type="primary" size={`large`} icon={<EditOutlined/>} onClick={() => setIsModalDate(true)}>
          แก้ไขวันที่ครบกำหนด
        </Button>
        <Button onClick={showModalStatusOpen} type="secondary" size={`large`}>เปลี่ยนสถานะ</Button>
        <Button type="default" size={`large`} mr={`10px`}>ลบ</Button>
      </Space>
    </Header>
    <Tabs size="large" defaultActiveKey="1" items={itemsPdf} onChange={onChangePreview}/>

    <Modal title="ส่งอีเมล" open={isModalEmail} footer={null} onCancel={() => setIsModalEmail(false)}>
      <Input size={`large`} ref={emailRef} defaultValue={currentEmail} placeholder="กรอกอีเมล"/>
      {error && <Alert
        message="Error Text"
        description={error.message}
        type="error"
        style={{ marginTop: '10px' }}
        closable={{
          'aria-label': 'close', closeIcon: <CloseSquareFilled/>,
        }}
      />}
      <Button type={`primary`} size={`large`} style={{ marginTop: '10px' }} loading={loading}
              onClick={confirmSendEmail}>ส่งอีเมล</Button>
    </Modal>

    <Modal title="แก้ไขวันที่ครบกำหนด" open={isModalDate} footer={null} onCancel={() => setIsModalDate(false)}>
      <DatePicker onChange={onChangeDate}
                  defaultValue={moment(new Date(invoice_group.due_date), dateFormat)}
                  needConfirm/>
      <Button type={`primary`} size={`large`} style={{ marginTop: '10px' }} loading={loading}
              onClick={confirmDate}>แก้ไข</Button>
    </Modal>

    <Modal title="เปลี่ยนสถานะ" open={isModalStatusOpen} footer={null} onCancel={handleCanceStatusOpen}>
      <Space direction="vertical">
        <div>
          <Select
            style={{
              width: '472px',
            }}
            size="large" placeholder="เลือกสถานะ"
            onChange={handleChangeSelect} options={optionSelect}/>
        </div>
        {status === 'rv_created' &&
          <Card title="รายละเอียด สร้างใบรับเงิน" size="small"
                style={{
                  width: '472px',
                }}
          >
            <Space direction="vertical">
              <div>วันตัดรอบ:</div>
              <DatePicker
                defaultValue={moment(new Date(), dateFormat)}
                format={dateFormat}
                size={`large`}
                onChange={(event) => setReportDate(event._d)}
              />
              <Space>
                <div style={{ width: 180 }}>
                  <div>วันที่สถาบันการเงินได้รับเงิน:</div>
                  <DatePicker
                    defaultValue={moment(new Date(), dateFormat)}
                    format={dateFormat}
                    size={`large`}
                    onChange={(event) => setTransferDate(event._d)}
                  />
                </div>
                <div>
                  <div>เข้าบัญชีสถาบันการเงิน (บาท):</div>
                  <Input size={`large`} defaultValue={lenderReceived}
                         onChange={(e) => setLenderReceived(e.target.value)}/>
                </div>
              </Space>
              <Space>
                <div style={{ width: 180 }}>
                  <div>วันที่ noburo ได้รับเงิน:</div>
                  <DatePicker
                    defaultValue={moment(new Date(), dateFormat)}
                    format={dateFormat}
                    size={`large`}
                    onChange={(event) => setTransferDateNoburo(event._d)}
                  />
                </div>
                <div>
                  <div>เข้าบัญชี noburo (บาท):</div>
                  <Input size={`large`} defaultValue={noburoReceived}
                         onChange={(e) => setNoburoReceived(e.target.value)}/>
                </div>
              </Space>
            </Space>
          </Card>
        }
        <Button
          size={`large`}
          style={{ marginTop: '10px' }}
          type="primary"
          loading={loadingUpdateStatus}
          onClick={handleOkStatusOpen}
        >
          ตกลง
        </Button>
      </Space>
    </Modal>
  </Layout>)
}

export default InvoicePreview
