import React from 'react';
import { Image } from 'antd';

const WebViewAnswers = ({ answers }) => {
  return (
    <div className='d-flex flex-column'>
      {answers.map(({ key, type, value }) => (<React.Fragment key={`${key}-${type}`}>
        <Answer keyName={key} type={type} value={value} />
        <hr />
      </React.Fragment>)
      )}

    </div>
  )
}

const Answer = ({keyName, type, value}) => {
  if (type === 'string') {
    return(
      <h5>
        {`${keyName}: `}
        <small>
          {value}
        </small>
      </h5>
    )
  }

  if (type === 'image') {
    return(
      <>
        <h5>  {keyName}</h5>
        <Image src={value} width={200}/>
      </>

    )
  }

  if (type === 'pdf') {
    return (
      <>
        <h5> {keyName}</h5>
        <a className='btn btn-primary w-50' onClick={() => openNewTab(value)}>
          Download PDF file
        </a>
      </>

    )
  }

  if (type === 'object') {
    return (<>
      <h5>
        {'key: '}
        <small>
          {keyName}
        </small>
      </h5>
      <h5>
        {'type: '}
        <small>
          {type}
        </small>
      </h5>
      <h5>
        {'value: '}
        <small>
          {`${JSON.stringify(value, null, 4)}`}
        </small>
      </h5>
    </>)
  }

  return(<>
    <h5>
      {'key: '}
      <small>
        {keyName}
      </small>
    </h5>
    <h5>
      {'type: '}
      <small>
        {type}
      </small>
    </h5>
    <h5>
      {'value: '}
      <small>
        {value}
      </small>
    </h5>
  </>)
}

const openNewTab = (value) => {
  const pdfWindow = window.open('')
  pdfWindow.document.write(
    `<iframe width='100%' height='100%' src='${value}'></iframe>`
  )
}

export default WebViewAnswers;
