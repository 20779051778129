import React from 'react'
import PropTypes from 'prop-types'
import TrueFalseQuiz from './TrueFalseQuiz'
import MultipleAnswersQuiz from './MultipleAnswersQuiz'
import SingleAnswerQuiz from './SingleAnswerQuiz'
import UploadImagesQuiz from './UploadImagesQuiz'
import WritingQuiz from './WritingQuiz'
import WebViewQuiz from './WebViewQuiz'
import ApplyProductQuiz from './ApplyProductQuiz'
import _ from 'lodash';

const Quiz = (params) => {
  const [quizType, setQuizType] = React.useState(params.quiz_type ?? 'TrueFalseQuiz')

  const types = {
    'TrueFalseQuiz': TrueFalseQuiz,
    'MultipleAnswersQuiz': MultipleAnswersQuiz,
    'SingleAnswerQuiz': SingleAnswerQuiz,
    'UploadImagesQuiz': UploadImagesQuiz,
    'WritingQuiz': WritingQuiz,
    'WebViewQuiz': WebViewQuiz,
    'ApplyProductQuiz': ApplyProductQuiz
  }

  const DisplayQuiz = types[quizType] ?? TrueFalseQuiz
  return (
    <DisplayQuiz {...params} quizType={quizType} setQuizType={setQuizType}/>
  )
}

const getNoMap = (quizzes) => {
  let no = 0
  return quizzes.map((quiz)=> {
    no = quiz.hasOwnProperty('_destroy') ? no : no + 1;
    return no
  })
}

const QuizFormV2 = (params) => {
  const [quizzes, setQuizzes] = React.useState(params.quizzes ?? [])

  const noMap = getNoMap(quizzes)

  const addQuiz = () => {
    const quiz = quizzes.find(obj => obj.hasOwnProperty('_destroy'))
    if(quiz){
      delete quiz._destroy
      setQuizzes([...quizzes])
    } else {
      setQuizzes(quizzes.concat([{ type: 'TrueFalseQuiz' }]))
    }
  }

  const removeQuiz = () => {
    const quiz = quizzes.findLast( obj => !obj.hasOwnProperty('_destroy'))
    if (!quiz) return

    if (quiz.id) {
      quiz['_destroy'] = 'true'
      setQuizzes([...quizzes])
    } else {
      setQuizzes(_.reject(quizzes, quiz))
    }
  }

  return (
    <React.Fragment>
      <h2>
        คำถามทั้งหมด
        <small>{` ${_.last(noMap) ?? 0} คำถาม`}</small>
      </h2>
      {
        quizzes.map((quiz, index )=> (
          <div key={`${index}-${quiz.quiz_type}-${quiz._destroy}`}>
            <Quiz {...quiz} no={noMap[index]} index={index} setQuizzType={(quizeType) => {
              quizzes[index]['type'] = quizeType
              setQuizzes([...quizzes])
            } } />
          </div>
        ))
      }

      <div className="float-right mt-3">
        <button type="button" className="btn btn-outline-primary mr-2" onClick={addQuiz}>
          <i className="fas fa-plus"/>
          &nbsp;
          เพิ่มคำถาม
        </button>

        <button type="button" className="btn btn-outline-danger" onClick={removeQuiz}>
          <i className="fas fa-minus" />
          &nbsp;
          ลบคำถาม
        </button>
      </div>

    </React.Fragment>
  )
}

export default QuizFormV2
