import React, { useState, useRef } from 'react'
import Layout from '../../Layout'
import { TableStyle } from './style'
import Header from '../../Header'
import CustomTable from '../../CustomTable'
import useAxios from 'axios-hooks'
import {Card, Space, Typography, Row, Col, Modal, Select, Button, Input, message, DatePicker} from 'antd'
import { StatusBar } from '../../Invoice/InvoiceSummary/style'
import InvoiceStatus from '../../Invoice/InvoiceStatus'
import { EditStyle } from '../../Invoice/InvoiceSummaryNotResign/style'
import {EditOutlined, CheckOutlined, MailOutlined, SendOutlined, FileOutlined, PlusOutlined} from '@ant-design/icons'
import { format } from 'date-fns'
import currency from "currency.js";
import moment from "moment/moment";
const ReceiptDetails = ({ receipt_summary, lender, partner, partially_paid, email: currentEmail }) => {

  const dateFormat = 'DD/MM/YYYY'
  const email = useRef()
  const receipt_summary_id = receipt_summary.id
  const { Title, Text, Paragraph } = Typography
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [statusView, setStatusView] = useState(receipt_summary.status)
  const [noteView, setNoteView] = useState(receipt_summary.note)
  const [noteLenderView, setNoteLenderView] = useState(receipt_summary.lender_note)
  const [isModalReportOpen, setIsModalReportOpen] = useState(false)
  const [typeReport, setTypeReport] = useState('noburo')
  const [lenderReceived, setLenderReceived] = useState(receipt_summary.lender_received)
  const [noburoReceived, setNoburoReceived] = useState(receipt_summary.noburo_received)
  const [transferDate, setTransferDate] = useState(receipt_summary.transfer_dat ? new Date(receipt_summary.transfer_date) : '')
  const [transferDateNoburo, setTransferDateNoburo] = useState(receipt_summary.transfer_date ? new Date(receipt_summary.transfer_date_noburo) : '')
  const [isModalStatusOpen, setIsModalStatusOpen] = useState(false)
  const [isModalEmailOpen, setIsModalEmailOpen] = useState(false)
  const [status, setStatus] = useState('')

  const columns = [{
    title: 'เลขที่เอกสาร', dataIndex: 'receipt_no', key: 'receipt_no', render: (text) => <a>{text}</a>,
  }, {
    title: 'เลขบัตรประชาชน', dataIndex: 'citizen_id', key: 'citizen_id', render: (text) => <a>{text}</a>,
  }, {
    title: 'ชื่อ', dataIndex: 'firstname', key: 'firstname', render: (text) => <a>{text}</a>,
  }, {
    title: 'นามสกุล', dataIndex: 'lastname', key: 'lastname', render: (text) => <a>{text}</a>,
  }, {
    title: 'วันที่สร้าง', dataIndex: 'created_at', key: 'created_at', render: (text) => <a>{format(new Date(text), 'yyyy-MM-dd')}</a>,
  }, {
    title: 'ค่าแอปพลิเคชัน', dataIndex: 'application_fee', key: 'application_fee', render: (text) => <a>{text}</a>,
  }, {
    title: 'ค่าธรรมเนียมเบิกถอน', dataIndex: 'withdrawal_fee', key: 'withdrawal_fee', render: (text) => <a>{text}</a>,
  }, {
    title: 'ภาษีมูลค่าเพิ่ม 7%', dataIndex: 'total_vat', key: 'total_vat', render: (text) => <a>{text}</a>,
  }, {
    title: 'รวม', dataIndex: 'total', key: 'total', render: (text) => <a>{text}</a>,
  }]


  const [{ loading: loadingUpdateSummary, error: errorUpdateSummary }, updateSummary] = useAxios({
    url: `/admins/api/receipt_summaries/update`, method: 'POST', headers: {
      'Content-Type': 'application/json'
    },
  }, {
    manual: true
  })

  const [{ data, loading, error }, refetch] = useAxios({
    url: `/admins/api/receipt_summaries/${receipt_summary_id}/receipt_details?page=${currentPage}&search=${search}`,
    method: 'GET',
    headers: {
      'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0',
    },

  }, {
    useCache: false,
  })

  const [{ loading: loadingSendEmail, error: errorSendEmail }, sendEmail] = useAxios({
    url: `/admins/api/receipt_summaries/send_email`, method: 'POST', headers: {
      'Content-Type': 'application/json'
    },
  }, {
    manual: true
  })

  const [{ loading: loadingCreateXlsx, error: errorCreateXlsx }, createXlsx] = useAxios({
    url: `/admins/api/receipt_summaries/create_xlsx`, method: 'POST', headers: {
      'Content-Type': 'application/json'
    },
  }, {
    manual: true
  })

  const handleCreateXlsx = async () => {
    try {
      await createXlsx({
        data: JSON.stringify({
          type: typeReport,
          receipt_summary_id
        })
      })

      message.success({
        content: 'สร้างรายงานสำเร็จ คลิ๊กที่เพื่ือตรวจสอบไฟล์ และ ดาวน์โหลด',
        duration: 5,
        onClick: () => {
          window.open(`/admins/requested_files`)
        }
      })
      await refetch()
    } catch (e) {
      console.log(e)
    }
    setIsModalReportOpen(false)
  }

  const handleOkStatusOpen = async () => {

    let result = {
      status,
      receipt_summary_ids: [receipt_summary_id]
    }
    transferDate && (result.transfer_date = format(transferDate, 'yyyy-MM-dd'))
    transferDateNoburo && (result.transfer_date_noburo = format(transferDateNoburo, 'yyyy-MM-dd'))
    lenderReceived && (result.lender_received = +lenderReceived)
    noburoReceived && (result.noburo_received = +noburoReceived)
    if(status !== 'cancelled') {
      if (
          (parseInt(lenderReceived) > 0 && transferDate !== null && transferDate !== undefined && transferDate !== "") ||
          (parseInt(noburoReceived) < 0 && (transferDate === null || transferDate === undefined || transferDate === "")) ||
          (parseInt(lenderReceived) < 0 && (transferDate === null || transferDate === undefined || transferDate === "")) ||
          (parseInt(noburoReceived) > 0 && transferDateNoburo !== null && transferDateNoburo !== undefined && transferDateNoburo !== "") ||
          (parseInt(noburoReceived) > 0 && transferDateNoburo !== "") ||
          (parseInt(lenderReceived) > 0 && transferDate !== "")
      ) {
      } else {
        message.error('กรุณากรอกยอดเงินที่เข้าอย่างใดอย่างหนึ่ง หรือ ทั้งสองยอด')
        return false
      }
    }


    try {
      await updateSummary({
        data: JSON.stringify(result)
      })

      setStatusView(optionSelect.find((item) => item.id === status).value)

    } catch (e) {

    }
    setIsModalStatusOpen(false)
  }

  const optionSelect = [
    {
      id: 1,
      value: 'paid',
      label: 'ชำระแล้ว',
    },
    {
      id: 2,
      value: 'cancelled',
      label: 'ยกเลิก',
    }
  ]

  const handleChangeSelect = (value, raw) => {
    setStatus(raw.id)
  }

  const handleSendEmail = async () => {
    try {
      await sendEmail({
        data: JSON.stringify({
          email: email.current.input.value,
          ids: [receipt_summary_id]
        })
      })
      setIsModalEmailOpen(false)
    } catch (e) {

    }
  }

  const optionSelectReport = [{
        id: 1, value: 'noburo', label: 'noburo',
      }, {
        id: 2, value: 'lender', label: 'Lender',
      }]

  return (<Layout>
    <StatusBar>
      <InvoiceStatus status={statusView === 'paid' ? partially_paid ? 'partially_paid' : statusView : statusView} size={`large`}/>
    </StatusBar>
    <Header title={`ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ : ${receipt_summary.tax_id}`}>
      <Space size="small">
        <Button type="primary" size={`large`} onClick={() => setIsModalEmailOpen(true)} icon={<SendOutlined/>}>
          ส่งอีเมล
        </Button>
        <Button type="primary" size={`large`} onClick={() => setIsModalReportOpen(true)} icon={<PlusOutlined/>}>
          สร้างรายงาน
        </Button>
        <Button type="primary" size={`large`} icon={<FileOutlined/>} onClick={() => window.open(`${receipt_summary_id}/preview`)}>
          ตัวอย่าง
        </Button>
        <Button onClick={() => setIsModalStatusOpen(true)} type="secondary" size={`large`}>
          เปลี่ยนสถานะ
        </Button>
      </Space>
    </Header>
    <Card
      title={`${lender.name} (${lender.lms_project_id})`}
      extra={<a href={`/admins/lenders/${lender.id}/edit`}>แก้ไข</a>}
      headStyle={{
        fontFamily: 'SukhumvitSet-Bold',
        background: '#efefef'
      }}
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <Card
            title="ชื่อลูกค้า"
            extra={<a href={`/admins/partners/${partner.id}/edit`}>แก้ไข</a>}
            style={{
              flex: 1,
            }}
          >
            <Space
              direction="vertical"
              size={0}
            >
              <Title style={{
                fontFamily: 'SukhumvitSet-Bold',
              }} level={5}>{partner.name}</Title>
              <Text>{partner.tax_id ? partner.tax_id : 'ไม่พบเลขผู้่เสียภาษี'} ({partner.branch ? partner.branch : 'สำนักงานใหญ่'})</Text>
              <Text>ที่อยู่ : {partner.address}</Text>
              <Text>{partner.subdistrict} {partner.district} {partner.province} {partner.post_code}</Text>
            </Space>
          </Card>
        </Col>
        <Col className="gutter-row" span={12}
             style={{
               display: 'flex',
             }}
        >
          <Card
            title="ข้อมูลเอกสาร"
            style={{
              flex: 1,
            }}
          >

            <Space>
              <Text>Note for Lender :
                <EditStyle style={{ width: 200 }}>
                  <Paragraph
                      editable={{
                        icon: <EditOutlined/>,
                        enterIcon: <CheckOutlined/>,
                        tooltip: 'Click To Edit Widget Name',
                        onChange: async (val) => {
                          setNoteLenderView(val)
                          await updateSummary({
                            data: JSON.stringify({
                              receipt_summary_ids: [receipt_summary_id],
                              lender_note: val
                            })
                          })
                        },
                        onStart: async (val) => {
                        },
                        onEnd: async () => {
                        }
                      }}
                  >
                    {noteLenderView ? noteLenderView : 'ไม่มี'}
                  </Paragraph></EditStyle>
              </Text>
              <Text>Note for noburo :
                <EditStyle style={{ width: 200 }}>
                  <Paragraph
                    editable={{
                      icon: <EditOutlined/>,
                      enterIcon: <CheckOutlined/>,
                      tooltip: 'Click To Edit',
                      onChange: async (val) => {
                        setNoteView(val)
                        await updateSummary({
                          data: JSON.stringify({
                            receipt_summary_ids: [receipt_summary_id],
                            note: val
                          })
                        })
                      },
                      onStart: async (val) => {
                      },
                      onEnd: async () => {
                      }
                    }}
                  >
                    {noteView ? noteView : 'ไม่มี'}
                  </Paragraph></EditStyle>
              </Text>
            </Space>
            <Space style={{ marginBottom: 16 }}>
              <Text>วันที่สถาบันการเงินได้รับเงิน :
                <br/>
                <EditStyle style={{width: 200}}>
                  {receipt_summary.transfer_date ? format(new Date(receipt_summary.transfer_date), 'yyyy-MM-dd') : 'ไม่มี'}
                </EditStyle>
              </Text>
              <Text>เข้าบัญชีสถาบันการเงิน (บาท) :
                <br/>
                <EditStyle style={{width: 200}}>
                  {lenderReceived ? currency(lenderReceived).format({
                    symbol: '',
                    separator: ',',
                    precision: 2
                  }) : 'ไม่มี'}
                </EditStyle>
              </Text>
            </Space>
            <Space>
              <Text>วันที่ noburo ได้รับเงิน :
                <br />
                <EditStyle style={{ width: 200 }}>
                  {receipt_summary.transfer_date_noburo ? format(new Date(receipt_summary.transfer_date_noburo), 'yyyy-MM-dd') : 'ไม่มี'}
                </EditStyle>
              </Text>
              <Text>เข้าบัญชี noburo (บาท) :
                <br />
                <EditStyle style={{ width: 200 }}>
                  {noburoReceived ? currency(noburoReceived).format({ symbol: '', separator: ',', precision: 2 }) : 'ไม่มี'}
                </EditStyle>
              </Text>
            </Space>
          </Card>
        </Col>
      </Row>
    </Card>
    <TableStyle>
      <CustomTable loading={loading} columns={columns}
                   data={data ? data.receipt_details : []} total={data ? data.total_pages : 0.}
                   currentPage={currentPage}
                   searchPlaceholder={`ค้นหาโดย เลขที่เอกสาร เลขบัตรประชาชน ชื่อ นามสกุล`}
                   setCurrentPage={setCurrentPage} setSearch={setSearch}/>
    </TableStyle>

    <Modal title="เปลี่ยนสถานะ" open={isModalStatusOpen} footer={null} onCancel={() => setIsModalStatusOpen(false)}>
      <Select
        style={{
          width: 240,
        }}
        size="large" placeholder="เลือกสถานะ"
        onChange={handleChangeSelect} options={optionSelect}/>
      {status === 1 &&
          <Card title="รายละเอียด การชำระเงิน" size="small"
                style={{
                  width: '472px',
                  marginTop: '8px'
                }}
          >
            <Space direction="vertical">
              <Space>
                <div style={{ width: 180 }}>
                  <div>วันที่สถาบันการเงินได้รับเงิน:</div>
                  <DatePicker
                      format={dateFormat}
                      size={`large`}
                      defaultValue={receipt_summary.transfer_date ? moment(new Date(receipt_summary.transfer_date), dateFormat) : undefined}
                      onChange={(event) => setTransferDate(event === null ? "" : event._d)}
                  />
                </div>
                <div>
                  <div>เข้าบัญชีสถาบันการเงิน (บาท):</div>
                  <Input size={`large`} defaultValue={receipt_summary.lender_received} onChange={(e) => setLenderReceived(e.target.value)}/>
                </div>
              </Space>
              <Space>
                <div style={{ width: 180 }}>
                  <div>วันที่ noburo ได้รับเงิน:</div>
                  <DatePicker
                      format={dateFormat}
                      size={`large`}
                      defaultValue={receipt_summary.transfer_date_noburo ? moment(new Date(receipt_summary.transfer_date_noburo), dateFormat) : undefined}
                      onChange={(event) => setTransferDateNoburo(event === null ? "" : event._d)}
                  />
                </div>
                <div>
                  <div>เข้าบัญชี noburo (บาท):</div>
                  <Input size={`large`} defaultValue={receipt_summary.noburo_received} onChange={(e) => setNoburoReceived(e.target.value)}/>
                </div>
              </Space>
            </Space>
          </Card>
      }
      <Button
        size={`large`}
        style={{ marginTop: '10px' }}
        type="primary"
        loading={loadingUpdateSummary}
        onClick={handleOkStatusOpen}
      >
        ตกลง
      </Button>
    </Modal>

    <Modal title="ส่งอีเมล" open={isModalEmailOpen} footer={null} onCancel={() => setIsModalEmailOpen(false)}>

      <Input size="large" ref={email} defaultValue={currentEmail} placeholder="กรอกอีเมล" prefix={<MailOutlined/>}/>
      <Button
        size={`large`}
        style={{ marginTop: '10px' }}
        type="primary"
        loading={loadingSendEmail}
        onClick={handleSendEmail}
      >
        ส่งอีเมล
      </Button>
    </Modal>

    <Modal title="สร้างรายงาน" open={isModalReportOpen} footer={null} onCancel={() => setIsModalReportOpen(false)}>
      <div>ประเภทรายงาน:</div>
      <Select
          style={{
            width: 280,
            marginBottom: '10px',
            marginTop: '10px',
          }}
          defaultValue={{
            id: 1, value: 'noburo', label: 'noburo',
          }}
          size="large" placeholder="ประเภทรายงาน"
          onChange={(select) => setTypeReport(select)} options={optionSelectReport}/>
      <Button
          size={`large`}
          style={{ marginTop: '10px' }}
          type="primary"
          loading={loadingCreateXlsx}
          onClick={handleCreateXlsx}
      >
        ตกลง
      </Button>
    </Modal>

  </Layout>)
}

export default ReceiptDetails
