import React from 'react'

import DestroyItem from '../DestroyItem'

const WebViewQuiz = ({ quizzes }) => {
  const size = quizzes.length || 1

  return (
    <React.Fragment>
      <Items quizzes={quizzes} size={size} />
    </React.Fragment>
  );
}

const Items = ({ quizzes, size }) => {
  const items = [];
  const formSize = Math.max(quizzes.length, size);

  for (let i = 0; i < formSize; i++) {
    if (i === 0) {
      items.push(<Item index={i} {...quizzes[i]} key={`upload-images-${i}`} />);
    } else {
      items.push(<DestroyItem index={i} id={quizzes[i].id} key={`upload-images-${i}`} />);
    }
  }

  return (<React.Fragment>{items}</React.Fragment>);
}

const Item = ({ id, index, no, title, extra = {} }) => {
  const [url, setUrl] = React.useState(extra.url ?? '');
  const [alwaysCorrect, setAlwaysCorrect] = React.useState(extra.always_correct === 'true')

  return (
    <>
      <div className="row" >
        <input type="hidden" value={id} name={`task[quizzes_attributes][${index}][id]`} />
        <input type="hidden" value='WebViewQuiz' name={`task[quizzes_attributes][${index}][type]`} />
        <input type="hidden" value={no || index + 1} name={`task[quizzes_attributes][${index}][no]`} />
        <div className="col-12 mt-2">
          <div className="form-group">
            <label>คำถามที่ {no || index + 1}</label>
            <input type='text' className='form-control' name={`task[quizzes_attributes][${index}][title]`} defaultValue={title} />
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label>URL (ขึ้นต้นด้วย https://)</label>
            <input
              type='text'
              className='form-control'
              name={`task[quizzes_attributes][${index}][url]`}
              placeholder='https://www.google.com/'
              value={url}
              onChange={event => setUrl(event.target.value)}
            />
          </div>
        </div>
        <div className="col-12">
          <label className="form-check">
            <input type="checkbox"
              className="form-check-input"
              id="check_always_correct"
              checked={alwaysCorrect}
              onChange={() => setAlwaysCorrect(!alwaysCorrect)}
            />
            <label className="form-check-label" htmlFor="check_always_correct">Always Correct</label>
          </label>
        </div>
        <input type='hidden' value={alwaysCorrect}  name={`task[quizzes_attributes][${index}][always_correct]`}/>
      </div>
      <input
        type="submit"
        name="commit"
        value="แก้ไข"
        className="btn btn-primary btn-block"
        data-disable-with="แก้ไข"
        disabled={ !url.startsWith('https://')}/>
    </>)
}

export default WebViewQuiz
