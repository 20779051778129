import React from 'react';
import { flatMap, map, sortBy, groupBy, compact, flatten} from 'lodash';

import Select from 'react-select';
import {Wrapper} from "./style";

const PartnerDropdown = (props) => {
  const { partners = [], selected = [], setPartners , name = 'partner_ids', isMulti = true, optionAll = false } = props
  const groupedPartners = groupBy(sortBy(partners, 'label'), 'group')
  const options = Object.keys(groupedPartners).sort().map((group)=>(
    { label: `${group}`,
      options: compact([
        (isMulti ? {label: group, value: map(groupedPartners[group], 'value')} : null),
        ...groupedPartners[group]
      ])
    }
  ));

  if (optionAll) {
    options.unshift({
      label: 'All',
      options: [{ label: 'All', value: map(partners, 'value') }]
    })
  }

  const [selectedOptions, setSelectedOptions] = React.useState(
    partners.filter(
      ({ value }) => (selected.includes(value))
    )
  )

  const onChange = (val) => {
    let partner_ids
    if (isMulti){
      partner_ids = flatMap(val, 'value')

      setSelectedOptions(
        partners.filter(
          ({ value }) => (partner_ids.includes(value))
        )
      )
      setPartners(
        partners.filter(
          ({ value }) => (partner_ids.includes(value))
        )
      )

    } else {
      setPartners([val])
      setSelectedOptions([val])
      partner_ids = val.value
    }
  }

  const formatGroupLabel = (data) => (
    <div>
      <span>{data.label}</span>
    </div>
  );

  return (
    <Wrapper {...props}>
      <Select
        width='400px'
        value={selectedOptions}
        onChange={onChange}
        options={flatten(options)}
        formatGroupLabel={formatGroupLabel}
        isMulti={isMulti}
        placeholder={'เลือกพาร์ทเนอร์'}
      />
    </Wrapper>
  );
}

export default PartnerDropdown
