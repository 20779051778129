import React,{useState} from 'react'
import CustomTable from '../../../CustomTable'
import { CardListStyle, CardStyle, EditStyle } from '../style'
import { Typography, Statistic } from 'antd'
import { EditOutlined, CheckOutlined } from '@ant-design/icons'
import useAxios from "axios-hooks";
import currency from "currency.js";

const OtherSummaryTable = ({ invoice_group_id }) => {

  const { Paragraph } = Typography
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState("")

  const [id, setId] = useState()
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url : `/admins/api/invoice_groups/${invoice_group_id}/other_summaries?page=${currentPage}&search=${search}`,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },

    },{
      useCache: false,
    })


  const [, adjust] = useAxios(
    {
      url: `/admins/api/invoice_groups/${invoice_group_id}/other_summaries/${id}`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
    },{
      manual: true
    }
  )

  const columns = [
    {
      title: 'ลำดับ',
      render: (text, record, index) => <a>{index+1}</a>,
    },
    {
    title: 'เลขบัตรประชาชน',
    dataIndex: 'citizen_id',
    key: 'citizen_id',
    render: (text) => <a>{text}</a>,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'firstname',
      key: 'firstname',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastname',
      key: 'lastname',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'ค่าสินเชื่อ',
      render: (text, _) => <a>{currency(_.raw_data.osb).format({ symbol: '', separator: ',', precision: 2 })}</a>,
    },
    {
      title: 'ค่าแอปพลิเคชัน',
      render: (text, _) => <a>{currency(_.raw_data.applicationFeeIncVat).format({ symbol: '', separator: ',', precision: 2 })}</a>,
    },

    {
      title: 'โอนให้ noburo',
      dataIndex: 'actual_noburo',
      key: 'actual_noburo',
      width: 300,
      render: (actual, _) => <EditStyle>
        <Paragraph
          editable={{
            icon: <EditOutlined/>,
            enterIcon: <CheckOutlined/>,
            tooltip: 'Click To Edit Widget Name',
            onChange: async (val) => {
              await adjust({
                data: JSON.stringify({
                  actual_noburo: currency(val).format({ symbol: '', separator: '', precision: 4 })
                })
              })
              await refetch()
            },
            onStart: async (val) => {
              setId(_.id)
            },
            onEnd: async () => {
              await refetch()
            }
          }}
        >
          {currency(actual).format({ symbol: '', separator: ',', precision: 2 })}
        </Paragraph></EditStyle>,
    },
  ]


  return <>
    <CardListStyle>
      <CardStyle>
        <Statistic title="รวมค่าสินเชื่อ" value={data ? data.total_osb : 0} precision={2}/>
      </CardStyle>
      <CardStyle>
        <Statistic title="รวมค่าแอปพลิเคชัน" value={data ? data.total_application_fee_inc_vat : 0} precision={2}/>
      </CardStyle>
      <CardStyle>
        <Statistic title="รวมโอนให้ noburo" value={data ? data.total_actual_noburo : 0} precision={2}/>
      </CardStyle>
    </CardListStyle>
    <CustomTable searchPlaceholder={`ค้นหาโดย ชื่อ นามสกุล เลขบัตรประชาชน`} loading={loading} columns={columns} data={data ? data.invoice_other_summaries : []} total={data ? data.total_pages : 0} currentPage={currentPage}
                 setCurrentPage={setCurrentPage} setSearch={setSearch}/>
  </>
}


export default OtherSummaryTable
