import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import chroma from 'chroma-js';
import Select from 'react-select';

const TaggingFilter = ({ current_tags, all_tags, color_list = {}, param_name = 'tag_list', placeholder = 'Filter by tagging' }) => {
  const [selectedOptions, setSelectedOptions] = React.useState(
    current_tags.map((str) => ({ value: str, label: str }))
  )

  const options = all_tags.map((str) => ({ value: str, label: str}));

  const onChange = (val) => {
    setSelectedOptions(val)
    const tag_list = map(val, 'value')

    const url = new URL(window.location.href)
    url.searchParams.set(param_name, tag_list)

    window.location.replace(url)
  }

  const customStyles = {
    option: (styles, { data }) => {
      const color = color_list[data.value] ?? styles.backgroundColor;
      return {
        ...styles,
        color: color,
      }
    },
    multiValue: (styles, { data }) => {
      const color = color_list[data.value] ?? styles.backgroundColor;
      return {
        ...styles,
        backgroundColor: chroma(color).alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => {
      const color = color_list[data.value] ?? styles.backgroundColor;
      return {
        ...styles,
        color: color,
      }
    },
  }

  return (
    <Select
      value={selectedOptions}
      onChange={onChange}
      styles={customStyles}
      options={options}
      isMulti={true}
      isClearable={false}
      placeholder={placeholder}
    />
  );
}

TaggingFilter.propTypes = {
  current_tags: PropTypes.arrayOf(PropTypes.string),
  all_tags: PropTypes.arrayOf(PropTypes.string),
  color_list: PropTypes.object,
  param_name: PropTypes.string,
  placeholder: PropTypes.string,
}

export default TaggingFilter
