import React from 'react';
import { Button, Form, Input, Empty, Result } from 'antd';

const WebForm = ({ postback }) => {
  const [submitted, setSubmitted] = React.useState(false)

  const onFinish = (values) => {
    setSubmitted(true)

    return fetch(postback, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);

  };

  if(submitted) {
    return (
      <Result
        status="success"
        title="Submitted"
      />
    )
  }

  return(
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please input your name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Info"
        name="info"
        rules={[{ required: true, message: 'Please input your info' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
        Submit
        </Button>
      </Form.Item>
    </Form>
  )}

const TestWebView = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const postback = queryParams.get('postback')

  if (postback)
    return (
      <WebForm postback={postback} />
    )
  else
    return(
      <Empty
        description={
          <span> No Postback </span>
        }
      />
    )
}

export default TestWebView;
