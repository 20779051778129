import styled, { css } from "styled-components";

export const Container = styled.div`
    padding: 0 0 0px 0;
`;


export const HeaderStyle = styled.div`
    display: flex;
    justify-content: space-between;
`;
