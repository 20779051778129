import React, {useRef, useState} from 'react'
import Layout from '../../Layout'
import { Tabs, Button, Space, Modal, Input, Alert, DatePicker, Typography, Select, Card, message, Col, Row } from 'antd'
import Header from '../../Header'
import { StatusBar } from './style'
import InvoiceStatus from "../../Invoice/InvoiceStatus";
import {FileOutlined, MailOutlined, SendOutlined} from "@ant-design/icons";
import useAxios from "axios-hooks";
const ReceiptPreview = ({ receipt_summary, lender, partner, html_lender, html_noburo, email: currentEmail }) => {

  const email = useRef()
  const receipt_summary_id = receipt_summary.id
  const [statusView, setStatusView] = useState(receipt_summary.status)
  const [isModalStatusOpen, setIsModalStatusOpen] = useState(false)
  const [isModalEmailOpen, setIsModalEmailOpen] = useState(false)
  const [status, setStatus] = useState('')

  const onChangePreview = (key) => {
    console.log(key)
  }

  const [{ loading: loadingUpdateSummary, error: errorUpdateSummary }, updateSummary] = useAxios({
    url: `/admins/api/receipt_summaries/update`, method: 'POST', headers: {
      'Content-Type': 'application/json'
    },
  }, {
    manual: true
  })

  const [{ loading: loadingSendEmail, error: errorSendEmail }, sendEmail] = useAxios({
    url: `/admins/api/receipt_summaries/send_email`, method: 'POST', headers: {
      'Content-Type': 'application/json'
    },
  }, {
    manual: true
  })

  const handleOkStatusOpen = async () => {
    try {
      await updateSummary({
        data: JSON.stringify({
          status,
          receipt_summary_ids: [receipt_summary_id]
        })
      })

      setStatusView(optionSelect.find((item) => item.id === status).value)

    } catch (e) {

    }
    setIsModalStatusOpen(false)
  }

  const optionSelect = [
    {
      id: 2,
      value: 'cancelled',
      label: 'ยกเลิก',
    }
  ]

  const handleChangeSelect = (value, raw) => {
    setStatus(raw.id)
  }

  const handleSendEmail = async () => {
    try {
      await sendEmail({
        data: JSON.stringify({
          email: email.current.input.value,
          ids: [receipt_summary_id]
        })
      })
      setIsModalEmailOpen(false)
    } catch (e) {

    }
  }

  const itemsPdf = [{
    key: '1',
    label: 'Lender',
    children: <iframe className="iframe-render-html" srcdoc={`
    <style>
      p[style="page-break-after:always;"] {
          margin: 64px 0;
          height: 2px;
          background: #EEE;
          width: 100%;
      }
    </style>
    ${html_lender}`}></iframe>,
  }, {
    key: '2',
    label: 'Noburo',
    children: <iframe className="iframe-render-html" srcdoc={`
    <style>
      p[style="page-break-after:always;"] {
          margin: 64px 0;
          height: 2px;
          background: #EEE;
          width: 100%;
      }
    </style>
    ${html_noburo}`}></iframe>,
  },]

  return (<Layout>
    <StatusBar>
      <InvoiceStatus status={statusView} size={`large`}/>
    </StatusBar>
    <Header title={`ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ : ${receipt_summary.tax_id}`}>
      <Space size="small">
        <Button type="primary" size={`large`} onClick={() => setIsModalEmailOpen(true)} icon={<SendOutlined/>}>
          ส่งอีเมล
        </Button>
        <Button onClick={() => setIsModalStatusOpen(true)} type="secondary" size={`large`}>
          เปลี่ยนสถานะ
        </Button>
      </Space>
    </Header>
    <Tabs size="large" defaultActiveKey="1" items={itemsPdf} onChange={onChangePreview}/>
    <Modal title="เปลี่ยนสถานะ" open={isModalStatusOpen} footer={null} onCancel={() => setIsModalStatusOpen(false)}>
      <Select
          style={{
            width: 240,
          }}
          size="large" placeholder="เลือกสถานะ"
          onChange={handleChangeSelect} options={optionSelect}/>

      <Button
          size={`large`}
          style={{ marginTop: '10px' }}
          type="primary"
          loading={loadingUpdateSummary}
          onClick={handleOkStatusOpen}
      >
        ตกลง
      </Button>
    </Modal>

    <Modal title="ส่งอีเมล" open={isModalEmailOpen} footer={null} onCancel={() => setIsModalEmailOpen(false)}>

      <Input size="large" ref={email} defaultValue={currentEmail} placeholder="กรอกอีเมล" prefix={<MailOutlined/>}/>
      <Button
          size={`large`}
          style={{ marginTop: '10px' }}
          type="primary"
          loading={loadingSendEmail}
          onClick={handleSendEmail}
      >
        ส่งอีเมล
      </Button>
    </Modal>
  </Layout>)
}

export default ReceiptPreview
