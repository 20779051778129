import React from 'react';
import PropTypes from 'prop-types';
import { flatMap, map, find, flatten } from 'lodash';

import Select from 'react-select';

const TaskProgressFilter = ({ missions, task_ids = [], autoRefresh = true, name }) => {
  const [selectedOptions, setSelectedOptions] = React.useState(
    flatMap(missions, 'options').filter(
      ({ value }) => (task_ids.includes(value) )
    )
  )

  const onChange = (val) => {
    const ids = flatten(
      val.map((v) => (
        v.value !== 0 ?  v.value : map(find(missions, {label: v.mission}).options, 'value')
      ))
    ).filter( id => id > 0 )

    setSelectedOptions(
      flatMap(missions, 'options').filter(
        ({ value }) => (ids.includes(value))
      )
    )

    const url = new URL(window.location.href)
    url.searchParams.set('task_ids', ids ?? '')

    if (autoRefresh) { window.location.replace(url) }
  }

  const formatGroupLabel = (data) => (
    <div>
      <span>{data.label}</span>
    </div>
  );

  return (
    <>
      <Select
        value={selectedOptions}
        onChange={onChange}
        options={missions}
        formatGroupLabel={formatGroupLabel}
        isMulti={true}
        isClearable={true}
        placeholder={'เลือกด่าน'}
      />
      {name && <input type="hidden" name={name} value={selectedOptions.map(({ value }) => value)} />}
    </>
  );
}

TaskProgressFilter.propTypes = {
  missions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }))
  })).isRequired,
  task_ids: PropTypes.arrayOf(PropTypes.number),
  autoRefresh: PropTypes.bool,
  name: PropTypes.string,
}

export default TaskProgressFilter
