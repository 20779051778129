import React from 'react'

const TrueFalseQuiz = ({ id, title, quizType, setQuizType, index, extra, _destroy, no}) => {
  const onChangeQuizType = (event) => {
    const quizType = event.target.value
    setQuizType(quizType)
  }

  if (_destroy) {
    return (
      <>
        <input type="hidden" value={_destroy} name={`task_v2[quiz_v2s_attributes][${index}][_destroy]`} />
        <input type="hidden" value={id} name={`task_v2[quiz_v2s_attributes][${index}][id]`} />
      </>
    )
  }

  return(
    <div className="card mt-3">
      <div className="card-body bg-light">
        <div className="row">
          <div className="col-8">
            <h5>
              {`คำถามที่ ${no}`}
            </h5>
            <input type="hidden" value={id} name={`task_v2[quiz_v2s_attributes][${index}][id]`} />
            <input type="hidden" value={no} name={`task_v2[quiz_v2s_attributes][${index}][no]`} />
            <textarea name={`task_v2[quiz_v2s_attributes][${index}][title]`} className='form-control' defaultValue={title} />
          </div>

          <div className="col-4">
            <label>ประเภทคำถาม</label>
            <select className="form-control" name={`task_v2[quiz_v2s_attributes][${index}][quiz_type]`} defaultValue={quizType} onChange={onChangeQuizType}>
              <option value='TrueFalseQuiz'>เลือกถูกหรือผิด</option>
              <option value='MultipleAnswersQuiz'>ตอบได้มากกว่าหนึ่งคำตอบ</option>
              <option value='SingleAnswerQuiz'>ตอบได้แค่คำตอบเดียว</option>
              <option value='UploadImagesQuiz'>อัปโหลดรูปภาพ</option>
              <option value='WritingQuiz'>บรรยายหรืออธิบาย</option>
              <option value='WebViewQuiz'>Webview</option>
              <option value='ApplyProductQuiz'>สมัครสินเชื่อ</option>
            </select>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <label className="mr-3">คำตอบ</label>
            <div className="form-check-inline">
              <label className="form-check-label"  >
                <input type="radio" className="form-check-input" name={`task_v2[quiz_v2s_attributes][${index}][extra][ans]`} value="true" defaultChecked={extra?.ans !== 'false'}/> ถูก
              </label>
            </div>
            <div className="form-check-inline">
              <label className="form-check-label" >
                <input type="radio" className="form-check-input" name={`task_v2[quiz_v2s_attributes][${index}][extra][ans]`} value="false" defaultChecked={extra?.ans === 'false'}/> ผิด
              </label>
            </div>
          </div>

          <div className="col-8">
            <label className="mr-3">อธิบายคำตอบ</label>
            <textarea className='form-control'
              name={`task_v2[quiz_v2s_attributes][${index}][extra][explain]`}
              defaultValue={extra?.explain}
            />
          </div>
        </div>

      </div>
    </div>
  )
}

export default TrueFalseQuiz
