import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker as DP } from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const DatePicker = ({defaultValue = '', placeholder = '', showTime = true, format = 'DD-MM-YYYY HH:mm', name = '', autoRefresh = false }) => {

  const [value, setValue] = React.useState(defaultValue);

  const onChange = (_date, dateString) => {
    setValue(dateString)

    if(autoRefresh) {
      const url = new URL(window.location.href)
      url.searchParams.set(name, dateString)
      window.location.replace(url)
    }
  }

  const _defaultValue = defaultValue ? dayjs(defaultValue, format) : null

  return(<>
    <DP onChange={onChange} format={format} showTime={showTime} placeholder={placeholder} defaultValue={_defaultValue}/>
    {name && value && <input type="hidden" name={name} value={value} />}
  </>);
}

DatePicker.propTypes = {
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  showTime: PropTypes.bool,
  format: PropTypes.string,
  name: PropTypes.string,
  autoRefresh: PropTypes.bool
}

export default DatePicker;
