import styled, { css } from "styled-components";

export const Container = styled.div`
    padding: 0 0 20px 0;
`;

export const PaginationStyle = styled.div`
    padding: 20px 0 0 0;
    display: flex;
    justify-content: flex-end;
`;

export const SearchStyle = styled.div`
    margin: 0 0 16px 0;
    display: flex;
    background-color: #fafafa;
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
`

export const ChildStyle = styled.div`
    width: 600px;
`
