import React from 'react'

const DestroyItem = ({ id, index }) => {
  return (
    <React.Fragment >
      <input type="hidden" value={id} name={`task[quizzes_attributes][${index}][id]`} />
      <input type="hidden" value={true} name={`task[quizzes_attributes][${index}][_destroy]`} />
    </React.Fragment >
  )
}

export default DestroyItem
