import styled, { css } from 'styled-components'

export const TableStyle = styled.div`
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 8px 20px;
`

export const EditStyle = styled.div`
    width: 300px;
`

export const TabStyle = styled.div`
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 8px 20px;
`
export const SearchStyle = styled.div`
    margin: 0 0 16px 0;
    display: flex;
    justify-content: flex-end;
`


export const StatusBar = styled.div`
    margin: 0 0 16px 0;
    display: flex;
    justify-content: flex-end;
`
