import React from 'react'

import DestroyItem from '../DestroyItem'

const MultipleAnswersQuiz = ({ quizzes }) => {
  const [size, setSize] = React.useState(quizzes.length || 1)

  return (
    <React.Fragment>
      <div className="my-2">
        ให้ผู้เข้าร่วมเลือกตอบคำถามที่ถูกต้อง
        <b> (ตอบได้มากกว่า 1 ข้อ)</b>
      </div>

      <Items quizzes={quizzes} size={size} />

      <div className="row form-group">
        <div className="col-6">
          <button type="button" className="btn btn-outline-primary btn-block" onClick={() => setSize(size + 1)}>
            <i className="fas fa-plus-circle" />
            {' เพิ่มคำถาม'}
          </button>
        </div>
        <div className="col-6">
          <button type="button" className="btn btn-outline-danger btn-block" onClick={() => setSize(size - 1 || 1)}>
            <i className="fas fa-minus-circle" />
            {' ลดคำถาม'}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

const Items = ({ quizzes, size }) => {
  const items = [];
  const formSize = Math.max(quizzes.length, size);

  for (let i = 0; i < formSize; i++) {
    if (i < size) {
      items.push(<Item index={i} {...quizzes[i]} key={`multiple-answers-${i}`} />);
    } else {
      items.push(<DestroyItem index={i} id={quizzes[i].id} key={`multiple-answers-${i}`} />);
    }
  }

  return (<React.Fragment>{items}</React.Fragment>);
}

const Item = ({ id, index, no, title, extra = {} }) => {
  const choices = extra.choices || [];
  const correct_indices = extra.correct_indices || [];

  return (
    <React.Fragment>
      <input type="hidden" value={id} name={`task[quizzes_attributes][${index}][id]`} />
      <input type="hidden" value='MultipleAnswersQuiz' name={`task[quizzes_attributes][${index}][type]`} />
      <input type="hidden" value={no || index + 1} name={`task[quizzes_attributes][${index}][no]`} />
      <div className="row" >
        <div className="col-12">
          <div className="form-group">
            <label>คำถามที่ {no || index + 1}</label>
            <input type='text' className='form-control' defaultValue={title} name={`task[quizzes_attributes][${index}][title]`}/>
          </div>
        </div>
      </div>

      {
        [0,1,2,3].map((i)=>(
          <div className="row" key={`multiple-answers-${index}-${i}`} >
            <div className="col-8">
              <div className="form-group">
                <label>คำตอบที่ {i + 1}</label>
                <input type='text' className='form-control' name={`task[quizzes_attributes][${index}][choices][]`} defaultValue={choices[i]}/>
              </div>
            </div>
            <div className="col-4">
              <label>คำตอบ</label>
              <select className="form-control" name={`task[quizzes_attributes][${index}][correct_indices][]`} defaultValue={correct_indices[i]}>
                <option value={i}>ถูก</option>
                <option value=''>ผิด</option>
              </select>
            </div>
          </div>
        ))
      }

    </React.Fragment>
  )
}

export default MultipleAnswersQuiz
