import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import {find} from 'lodash';

const ProductFilter = ({ products = [], name }) => {
  const [selectedOptions, setSelectedOptions] = React.useState([])
  const onChange = (val) => { setSelectedOptions(val) }

  const options =  products.reduce(
    (arr, p) => {
      var obj = find(arr, {label: p.label})
      if(!obj) {
        obj = { label: p.label, value: [] }
        arr.push(obj)
      }
      obj.value.push(p.value)

      return arr;
    },
    []
  )

  return(
    <>
      <Select
        value={selectedOptions}
        options={options}
        onChange={onChange}
        isMulti={true}
        placeholder={'เลือกสินเชื่อ'}
      />
      {name && <input type="hidden" name={name} value={selectedOptions.map(({ value }) => value)} />}
    </>
  )
}

ProductFilter.propTypes = {
  products: PropTypes.arrayOf(PropTypes.PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
  name: PropTypes.string,
}

export default ProductFilter
