import styled , { css } from 'styled-components'
export const StatusStyle = styled.div`
    min-width: 140px;
    text-align: center;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    padding: 4px 15px;
    ${props => {
        switch (props.status) {
            case 'paid':
                return css`
                    background-color: #2d96e0;
                    border-color: #2d96e0;
                    color: #FFF;
                `;
            case 'wait_confirm':
                return css`
                    background-color: #EEEEEE;
                    border-color: #d7d7d7;
                    color: black;
                `;
            case 'confirmed':
                return css`
                    background-color: #ecf8ff;
                    border-color: #2d96e0;
                    color: #2d96e0;
                `;
            case 'sent':
                return css`
                    background-color: #ecf8ff;
                    border-color: #2d96e0;
                    color: #2d96e0;
                `;
            case 'rv_created':
                return css`
                    background-color: #d3ffe7;
                    border-color: #00c554;
                    color: #00c554;
                `;              
            case 'partially_paid':
                return css`
                background-color: #ffebe2;
                border-color: #f65f00;
                color: #bc4900;
                `;  
            case 'cancelled':
                return css`
                    background-color: #ffeeee;
                    border-color: #e43838;
                    color: #e43838;
                `;
            default:
                return css`
                    background-color: #ecf8ff;
                    border-color: #2d96e0;
                    color: black;
                `;
        }
    }}

    ${props => {
        switch (props.size) {
            case 'small':
                return css`
                    padding: 2px 10px;
                    font-size: 12px;
                `;
            case 'large':
                return css`
                    padding: 6px 20px;
                    font-size: 18px;
                `;
            case 'default':
                return css`
                    padding: 4px 15px;
                `;
            default:
                return css`
                    padding: 4px 15px;
                `;
        }
    }}
`;
