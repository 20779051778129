import React from 'react';
import PropTypes from 'prop-types';

import { Tabs as AntdTabs } from 'antd';

const Tabs = ({tabs}) => {

  const items = tabs.map(
    (tab, i) => ({
      label: tab.name,
      key: `${i}`,
      children: <div style={tab.style}>
        <div  dangerouslySetInnerHTML={{ __html: tab.children }} />
      </div>,
    })
  );

  return (<AntdTabs
    size='large'
    type="card"
    defaultActiveKey="0"
    items={items}
  />)
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf({
    name: PropTypes.string,
    syntax: PropTypes.object,
    children: PropTypes.string
  }).isRequired
}

export default Tabs;
