import styled, { css } from 'styled-components'

export const TableStyle = styled.div`
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
`
