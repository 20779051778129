import React, { useState } from 'react'
import currency from 'currency.js'
import Layout from '../../Layout'
import { Space, Button, Modal, Select, DatePicker, Card, message, Input, Tabs } from 'antd'
import { FileAddOutlined } from '@ant-design/icons'
import {SpaceFilter, TableStyle} from './style'
import Header from '../../Header'
import ViewIcon from '../../../../assets/images/icons/ic_view.svg'
import CustomTable from '../../CustomTable'
import InvoiceStatus from '../InvoiceStatus'
import ReportForm from '../ReportForm'
import useAxios from 'axios-hooks'
import moment from 'moment'
import { format, startOfMonth, endOfMonth } from 'date-fns'
import PartnerDropdown from '../../PartnerDropdown';

const InvoiceGroup = ({ lenders, lender_list, partner_list }) => {

  const dateFormat = 'DD/MM/YYYY'

  const currentDate = new Date()
  const firstDateThisMonth = startOfMonth(currentDate);
  const lastDateThisMonth = endOfMonth(currentDate);
  const [rangeDate, setRangeDate] = useState([firstDateThisMonth, lastDateThisMonth])

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [pageSize, setPageSize] = useState(20)
  const [statusDisplay, setStatusDisplay] = useState('')
  const [reportDate, setReportDate] = useState(new Date())
  const [transferDate, setTransferDate] = useState()
  const [transferDateNoburo, setTransferDateNoburo] = useState()
  const [overdue, setOverdue] = useState("")
  const [lender, setLender] = useState("")
  const [partners, setPartners] = useState([])

  const [lenderReceived, setLenderReceived] = useState(0)
  const [noburoReceived, setNoburoReceived] = useState(0)
  const [isModalCreateReportOpen, setIsModalCreateReportOpen] = useState(false)
  const [isModalStatusOpen, setIsModalStatusOpen] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const { RangePicker } = DatePicker
  const showModalReportOpen = () => {
    setIsModalCreateReportOpen(true)
  }
  const handleOkReportOpen = () => {
    setIsModalCreateReportOpen(false)
  }
  const handleCanceReportOpen = () => {
    setIsModalCreateReportOpen(false)
  }

  const showModalStatusOpen = () => {
    setIsModalStatusOpen(true)
  }

  const handleOkStatusOpen = async () => {

    let result = {
      status,
      invoice_group_ids: selectedRowKeys,
      report_date: format(reportDate, 'yyyy-MM-dd'),
    }
    transferDate && (result.transfer_date = format(transferDate, 'yyyy-MM-dd'))
    transferDateNoburo && (result.transfer_date_noburo = format(transferDateNoburo, 'yyyy-MM-dd'))
    lenderReceived && (result.lender_received = +lenderReceived)
    noburoReceived && (result.noburo_received = +noburoReceived)

    if(status === 'rv_created') {
      if (
          (parseInt(lenderReceived) > 0 && transferDate !== null && transferDate !== undefined && transferDate !== "") ||
          (parseInt(noburoReceived) < 0 && (transferDate === null || transferDate === undefined || transferDate === "")) ||
          (parseInt(lenderReceived) < 0 && (transferDate === null || transferDate === undefined || transferDate === "")) ||
          (parseInt(noburoReceived) > 0 && transferDateNoburo !== null && transferDateNoburo !== undefined && transferDateNoburo !== "")
      ) {} else {
        message.error('กรุณากรอกยอดเงินที่เข้าอย่างใดอย่างหนึ่ง')
        return false
      }

      if(!reportDate) {
        message.error('กรุณากรอกวันตัดรอบ')
        return false
      }
    }


    try {

      await updateStatus({
        data: JSON.stringify(result)
      })

      status === 'rv_created' && message.success({
        content: 'สร้างใบรับเงินเรียบร้อย คลิ๊กเพื่อตรวจสอบ',
        duration: 5,
        onClick: () => {
          window.open(`/admins/receipt/receipt_summaries`)
        }
      })
      setSelectedRowKeys([])
      await refetch()
    } catch (e) {

    }
    setIsModalStatusOpen(false)
  }

  const handleCanceStatusOpen = () => {
    setIsModalStatusOpen(false)
  }
  const handleChangeSelect = (value) => {
    setStatus(value)
  }

  const [{ loading: loadingUpdateStatus, error: errorUpdateStatus }, updateStatus] = useAxios({
    url: `/admins/api/invoice_groups/update`, method: 'PATCH', headers: {
      'Content-Type': 'application/json'
    },
  }, {
    manual: true
  })

  const optionSelect = [
    {
      id: 0,
      value: 'bn_created',
      label: 'สร้างใบเรียกเก็บแล้ว',
    },
    {
      id: 3,
      value: 'sent',
      label: 'รอรับชำระ',
    },
    {
      id: 5,
      value: 'cancelled',
      label: 'ยกเลิก',
    }
  ]

  const columns = [{
    title: 'เลขที่เอกสาร', dataIndex: 'no', key: 'invoice_no', width: 200, render: (text) => <a>{text}</a>,
  }, {
    title: 'ผู้ให้สินเชื่อ', dataIndex: 'lender_name', key: 'lender', render: (text) => <a>{text}</a>,
  }, {
    title: 'พาร์ทเนอร์', dataIndex: 'partner_name', key: 'partner', render: (text) => <a>{text}</a>,
  }, {
    title: 'วันที่สร้าง',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (date) => <a>{format(new Date(date), 'yyyy-MM-dd')}</a>,
  }, {
    title: 'วันครบกำหนด', dataIndex: 'due_date', key: 'due_date', render: (date) => <a>{date}</a>,
  }, {
    title: 'ยอดเรียกเก็บ',
    render: (item, record) => <a>{currency((+item.total_expected_deduction)).format({
      symbol: '',
      separator: ',',
      precision: 2
    })}</a>,
  }, {
    title: 'ยอดหักเงินเดือน',
    render: (item, record) => <a>{currency((+item.total_lender) + (+item.total_noburo)).format({
      symbol: '',
      separator: ',',
      precision: 2
    })}</a>,
  }, {
    title: 'โอนให้สถาบันการเงิน',
    render: (item, record) => <a>{currency((+item.total_lender)).format({
      symbol: '',
      separator: ',',
      precision: 2
    })}</a>,
  }, {
    title: 'โอนให้ noburo',
    render: (item, record) => <a>{currency((+item.total_noburo)).format({
      symbol: '',
      separator: ',',
      precision: 2
    })}</a>,
  }, {
    title: 'สถานะ', key: 'action', render: (item, record) => (<Space size="middle">
      <InvoiceStatus status={item.status === "sent" ? (record.overdue ? 'overdue': item.status): item.status}/>
    </Space>),
  }, {
    title: 'Action', key: 'action', render: (item, record) => (<Space size="middle">

      <a href={`/admins/invoice/invoice_summary/${item.id}`}>
        <img src={ViewIcon} width={24} height={24} alt={`Icon`}/>
      </a>
    </Space>),
  },]

  const partnerList = partners.length !== 0 ? partners.map((partner) => partner.value).join(',') : ''
  const url = `/admins/api/invoice_groups?per_page=${pageSize}&start_date=${format(rangeDate[0], 'yyyy-MM-dd')}&end_date=${format(rangeDate[1], 'yyyy-MM-dd')}&page=${currentPage}&search=${search}${statusDisplay && `&status=${statusDisplay}` }${overdue && `&overdue=${overdue}` }${lender && `&lender=${lender}`}${partnerList && `&partner_ids=${partnerList}`}`
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: url,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },

    }, {
      useCache: false,
    })

  const onChange = (key) => {
    setSelectedRowKeys([]);
    setCurrentPage(1);
    switch (key) {
      case "1":
        setStatusDisplay('');
        setOverdue('');
        break;
      case "2":
        setStatusDisplay('bn_created');
        setOverdue('');
        break;
      case "3":
        setStatusDisplay('sent');
        setOverdue('false');
        break;
      case "4":
        setStatusDisplay('sent');
        setOverdue('true');
        break;
      case "5":
        setStatusDisplay('rv_created');
        setOverdue('');
        break;
      case "6":
        setStatusDisplay('cancelled');
        setOverdue('');
        break;
      default:
        console.log('Invalid key');
        break;
    }
  };

  const items = [
    {
      key: '1',
      label: `ทั้งหมด (${data ? data.count.bn_created + data.count.sent + data.count.rv_created + data.count.cancelled : 0})`,
      children: <CustomTable selectedRowKeys={selectedRowKeys}
                             pageSize={pageSize}
                             setPageSize={setPageSize}
                             searchPlaceholder={`ค้นหาโดย เลขที่เอกสาร ผู้ให้สินเชื่อ พาร์ทเนอร์`}
                             setSelectedRowKeys={setSelectedRowKeys} loading={loading} columns={columns}
                             data={data ? data.invoice_groups : []} total={data ? data.total_pages : 0.}
                             currentPage={currentPage}
                             setCurrentPage={setCurrentPage} setSearch={setSearch}>
        <SpaceFilter>
          <Select
              style={{
                width: "300px",
                height: "35px"
              }}
              defaultValue={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"}]}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder="เลือกผู้ให้สินเชื่อ"
              onChange={(select) => setLender(select)}
              options={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"} , ...lender_list]}/>
          <RangePicker
              style={{
                width: 280,
              }}
              size={`middle`}
              format={dateFormat}
              defaultValue={[moment(firstDateThisMonth), moment(lastDateThisMonth)]}
              onChange={(event) => setRangeDate([event[0]._d, event[1]._d])}
          />
        </SpaceFilter>
        <PartnerDropdown partners={partner_list} setPartners={setPartners} mt={`8px`} />
      </CustomTable>
    },
    {
      key: '2',
      label: `สร้างใบเรียกเก็บ (${data ? data.count.bn_created : 0})`,
      children: <CustomTable selectedRowKeys={selectedRowKeys}
                             pageSize={pageSize}
                             setPageSize={setPageSize}
                             searchPlaceholder={`ค้นหาโดย เลขที่เอกสาร ผู้ให้สินเชื่อ พาร์ทเนอร์`}
                             setSelectedRowKeys={setSelectedRowKeys} loading={loading} columns={columns}
                             data={data ? data.invoice_groups : []} total={data ? data.total_pages : 0.}
                             currentPage={currentPage}
                             setCurrentPage={setCurrentPage} setSearch={setSearch}>
        <SpaceFilter>
          <Select
              style={{
                width: "300px",
                height: "35px"
              }}
              defaultValue={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"}]}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder="เลือกผู้ให้สินเชื่อ"
              onChange={(select) => setLender(select)}
              options={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"} , ...lender_list]}/>
          <RangePicker
              style={{
                width: 280,
              }}
              size={`middle`}
              format={dateFormat}
              defaultValue={[moment(firstDateThisMonth), moment(lastDateThisMonth)]}
              onChange={(event) => setRangeDate([event[0]._d, event[1]._d])}
          />
        </SpaceFilter>
        <PartnerDropdown partners={partner_list} setPartners={setPartners} mt={`8px`} />
      </CustomTable>
    },
    {
      key: '3',
      label: `รอรับชำระ (${data ? data.count.sent : 0})`,
      children: <CustomTable selectedRowKeys={selectedRowKeys}
                             pageSize={pageSize}
                             setPageSize={setPageSize}
                             searchPlaceholder={`ค้นหาโดย เลขที่เอกสาร ผู้ให้สินเชื่อ พาร์ทเนอร์`}
                             setSelectedRowKeys={setSelectedRowKeys} loading={loading} columns={columns}
                             data={data ? data.invoice_groups : []} total={data ? data.total_pages : 0.}
                             currentPage={currentPage}
                             setCurrentPage={setCurrentPage} setSearch={setSearch}>
        <SpaceFilter>
          <Select
              style={{
                width: "300px",
                height: "35px"
              }}
              defaultValue={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"}]}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder="เลือกผู้ให้สินเชื่อ"
              onChange={(select) => setLender(select)}
              options={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"} , ...lender_list]}/>
          <RangePicker
              style={{
                width: 280,
              }}
              size={`middle`}
              format={dateFormat}
              defaultValue={[moment(firstDateThisMonth), moment(lastDateThisMonth)]}
              onChange={(event) => setRangeDate([event[0]._d, event[1]._d])}
          />
        </SpaceFilter>
        <PartnerDropdown partners={partner_list} setPartners={setPartners} mt={`8px`} />
      </CustomTable>
    },
    {
      key: '4',
      label: `เกินกำหนดชำระ (${data ? data.count.overdue : 0})`,
      children: <CustomTable selectedRowKeys={selectedRowKeys}
                             pageSize={pageSize}
                             setPageSize={setPageSize}
                             searchPlaceholder={`ค้นหาโดย เลขที่เอกสาร ผู้ให้สินเชื่อ พาร์ทเนอร์ สถานะ`}
                             setSelectedRowKeys={setSelectedRowKeys} loading={loading} columns={columns}
                             data={data ? data.invoice_groups : []} total={data ? data.total_pages : 0.}
                             currentPage={currentPage}
                             setCurrentPage={setCurrentPage} setSearch={setSearch}>
        <SpaceFilter>
          <Select
              style={{
                width: "300px",
                height: "35px"
              }}
              defaultValue={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"}]}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder="เลือกผู้ให้สินเชื่อ"
              onChange={(select) => setLender(select)}
              options={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"} , ...lender_list]}/>
          <RangePicker
              style={{
                width: 280,
              }}
              size={`middle`}
              format={dateFormat}
              defaultValue={[moment(firstDateThisMonth), moment(lastDateThisMonth)]}
              onChange={(event) => setRangeDate([event[0]._d, event[1]._d])}
          />
        </SpaceFilter>
        <PartnerDropdown partners={partner_list} setPartners={setPartners} mt={`8px`} />
      </CustomTable>
    },
    {
      key: '5',
      label: `ชำระแล้ว (${data ? data.count.rv_created : 0})`,
      children: <CustomTable selectedRowKeys={selectedRowKeys}
                             pageSize={pageSize}
                             setPageSize={setPageSize}
                             searchPlaceholder={`ค้นหาโดย เลขที่เอกสาร ผู้ให้สินเชื่อ พาร์ทเนอร์`}
                             setSelectedRowKeys={setSelectedRowKeys} loading={loading} columns={columns}
                             data={data ? data.invoice_groups : []} total={data ? data.total_pages : 0.}
                             currentPage={currentPage}
                             setCurrentPage={setCurrentPage} setSearch={setSearch}>
        <SpaceFilter>
          <Select
              style={{
                width: "300px",
                height: "35px"
              }}
              defaultValue={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"}]}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder="เลือกผู้ให้สินเชื่อ"
              onChange={(select) => setLender(select)}
              options={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"} , ...lender_list]}/>
          <RangePicker
              style={{
                width: 280,
              }}
              size={`middle`}
              format={dateFormat}
              defaultValue={[moment(firstDateThisMonth), moment(lastDateThisMonth)]}
              onChange={(event) => setRangeDate([event[0]._d, event[1]._d])}
          />
        </SpaceFilter>
        <PartnerDropdown partners={partner_list} setPartners={setPartners} mt={`8px`} />
      </CustomTable>
    },
    {
      key: '6',
      label: `ยกเลิก (${data ? data.count.cancelled : 0})`,
      children: <CustomTable selectedRowKeys={selectedRowKeys}
                             searchPlaceholder={`ค้นหาโดย เลขที่เอกสาร ผู้ให้สินเชื่อ พาร์ทเนอร์`}
                             setSelectedRowKeys={setSelectedRowKeys} loading={loading} columns={columns}
                             data={data ? data.invoice_groups : []} total={data ? data.total_pages : 0.}
                             currentPage={currentPage}
                             setCurrentPage={setCurrentPage} setSearch={setSearch}>
        <SpaceFilter>
          <Select
              style={{
                width: "300px",
                height: "35px"
              }}
              defaultValue={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"}]}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder="เลือกผู้ให้สินเชื่อ"
              onChange={(select) => setLender(select)}
              options={[{id: 0, value: "", label: "ผู้ให้สินเชื่อทั้งหมด"} , ...lender_list]}/>
          <RangePicker
              style={{
                width: 280,
              }}
              size={`middle`}
              format={dateFormat}
              defaultValue={[moment(firstDateThisMonth), moment(lastDateThisMonth)]}
              onChange={(event) => setRangeDate([event[0]._d, event[1]._d])}
          />
        </SpaceFilter>
        <PartnerDropdown partners={partner_list} setPartners={setPartners} mt={`8px`} />
      </CustomTable>
    },
  ]

  return (<Layout>
    <Header title={`ใบเรียกเก็บเงินแทน`}>
      <Space size="small">
        <Button onClick={showModalStatusOpen} type="secondary" size={`large`}
                disabled={selectedRowKeys.length === 0}>เปลี่ยนสถานะ</Button>
        <Button onClick={showModalReportOpen} type="primary" icon={<FileAddOutlined/>}
                size={`large`}>สร้างใบเรียกเงินเก็บแทน</Button>
      </Space>
    </Header>
    <TableStyle>
      <Tabs type="card" size="large" defaultActiveKey="1" items={items} onChange={onChange}/>
    </TableStyle>

    <Modal title="สร้างใบเรียกเก็บเงินแทน" footer={null} open={isModalCreateReportOpen} onOk={handleOkReportOpen}
           onCancel={handleCanceReportOpen}>
      <ReportForm setIsModalOpen={setIsModalCreateReportOpen} refetch={refetch} lenders={lenders}/>
    </Modal>

    <Modal title="เปลี่ยนสถานะ" open={isModalStatusOpen} footer={null} onCancel={handleCanceStatusOpen}>
      <Space direction="vertical">
        <div>
          <Select
            style={{
              width: '472px',
            }}
            size="large" placeholder="เลือกสถานะ"
            onChange={handleChangeSelect} options={optionSelect}/>
        </div>
        {status === 'rv_created' &&
          <Card title="รายละเอียด สร้างใบรับเงิน" size="small"
                style={{
                  width: '472px',
                }}
          >
            <Space direction="vertical">
              <div>วันตัดรอบ:</div>
              <DatePicker
                defaultValue={moment(new Date(), dateFormat)}
                format={dateFormat}
                size={`large`}
                onChange={(event) => setReportDate(event._d)}
              />
              <Space>
                <div style={{ width: 180 }}>
                  <div>วันที่สถาบันการเงินได้รับเงิน:</div>
                  <DatePicker
                    format={dateFormat}
                    size={`large`}
                    onChange={(event) => setTransferDate(event === null ? "" : event._d)}
                  />
                </div>
                <div>
                  <div>เข้าบัญชีสถาบันการเงิน (บาท):</div>
                  <Input size={`large`} defaultValue={0} onChange={(e) => setLenderReceived(e.target.value)}/>
                </div>
              </Space>
              <Space>
                <div style={{ width: 180 }}>
                  <div>วันที่ noburo ได้รับเงิน:</div>
                  <DatePicker
                    format={dateFormat}
                    size={`large`}
                    onChange={(event) => setTransferDateNoburo(event === null ? "" : event._d)}
                  />
                </div>
                <div>
                  <div>เข้าบัญชี noburo (บาท):</div>
                  <Input size={`large`} defaultValue={0} onChange={(e) => setNoburoReceived(e.target.value)}/>
                </div>
              </Space>
            </Space>
          </Card>
        }
        <Button
          size={`large`}
          style={{ marginTop: '10px' }}
          type="primary"
          loading={loadingUpdateStatus}
          onClick={handleOkStatusOpen}
        >
          ตกลง
        </Button>
      </Space>
    </Modal>
  </Layout>)
}

export default InvoiceGroup
