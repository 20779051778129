$(document).ready(() => {
  $('input.custom-control-input[type=checkbox][data-record=mission]').on('change', (e) => {
    const target = e.target
    const missionID = target.id.replace('missionPublishSwitch', '')
    const token = $('meta[name=csrf-token]').attr('content')

    fetch(`/admins/missions/${missionID}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        mission: {
          published_at: target.checked ? Date() : null,
        }
      }),
    }).then(() => {
      location.reload();
    })
  })

  $('input.custom-control-input[type=checkbox][data-record=mission_v2]').on('change', (e) => {
    const target = e.target
    const missionID = target.id.replace('missionPublishSwitch', '')
    const token = $('meta[name=csrf-token]').attr('content')

    fetch(`/admins/mission_v2s/${missionID}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        mission_v2: {
          published_at: target.checked ? Date() : null,
        }
      }),
    }).then(() => {
      location.reload();
    })
  })

})
