$(document).ready(() => {
  const onChangeLMSID = () => {
    let id = $('#lms_product_id_select').val();
    let interest = $('#lms_product_id_select').attr(`data-interest-${id}`)
    let payment = $('#lms_product_id_select').attr(`data-payment-${id}`)
    $('#lms_product_interest').val((interest * 100).toFixed(2))
    $('#lms_product_payment_type').val(payment)
  }

  $('#lms_product_id_select').on('change', (event) => {
    onChangeLMSID();
  })
})
