import React from 'react';
import PropTypes from 'prop-types';
import chroma from 'chroma-js';
import _ from 'lodash';

import CreatableSelect from 'react-select/creatable';

const Tagging = ({ current_tags, all_tags, csrf, model, update_url = '', color_list = {}, tag_field_name = 'tag_list', disabled = false}) => {
  const [selectedOptions, setSelectedOptions] = React.useState(
    current_tags.map((str) => ({value: str, label: str}))
  )

  const options = all_tags.map((str) => ({ value: str, label: str }));

  const onChange = (val) => {
    if (val.length < selectedOptions.length) {
      const diffTag = _.difference(selectedOptions, val)[0].value
      confirm(`Remove ${diffTag} ?`) ? requestChange(val) : console.log(`cancel remove ${diffTag}`)
    } else {
      requestChange(val)
    }
  }

  const requestChange = (val) => {
    setSelectedOptions(val)

    if (update_url) {
      fetch(update_url, {
        method: 'PATCH',
        redirect: 'manual',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          [model]: { [tag_field_name]: _.map(val, 'value') },
          authenticity_token: csrf,
        })
      });
    }
  }

  const customStyles = {
    option: (styles, { data }) => {
      const color = color_list[data.value] ?? styles.backgroundColor;
      return {
        ...styles,
        color: color,
      }
    },
    multiValue: (styles, { data }) => {
      const color = color_list[data.value] ?? styles.backgroundColor;
      return {
        ...styles,
        backgroundColor: chroma(color).alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => {
      const color = color_list[data.value] ?? styles.backgroundColor;
      return {
        ...styles,
        color: color,
      }
    },
  }

  return (
    <>
      <CreatableSelect
        value={selectedOptions}
        onChange={onChange}
        styles={customStyles}
        options={options}
        isMulti={true}
        isClearable={false}
        placeholder={'tagging'}
        isDisabled={disabled}
      />
      {
        _.map(selectedOptions, 'value').map((val) => (
          <input type="hidden" key={val} value={val} name={`${model}[${tag_field_name}][]`} />
        ))
      }
    </>
  );
}

Tagging.propTypes = {
  current_tags: PropTypes.arrayOf(PropTypes.string),
  all_tags: PropTypes.arrayOf(PropTypes.string),
  update_url: PropTypes.string,
  csrf: PropTypes.string,
  color_list: PropTypes.object,
  model: PropTypes.string,
  tag_field_name: PropTypes.string,
}

export default Tagging
