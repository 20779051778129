import React, {useEffect} from 'react'
import CustomTable from '../../../CustomTable'
import { Statistic , Space } from 'antd'
import { CardListStyle, CardStyle } from '../style'
import currency from "currency.js";
const SummaryTable = ({ data, loading, currentPage, setCurrentPage, setSearch, setLenderReceived , setNoburoReceived }) => {

  const columns = [
    {
      title: 'ลำดับ',
      render: (text, record, index) => <a>{index+1}</a>,
    },
    {
      title: 'เลขบัตรประชาชน',
      dataIndex: 'citizen_id',
      key: 'citizen_id',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'ชื่อ',
      dataIndex: 'firstname',
      key: 'firstname',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastname',
      key: 'lastname',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'ยอดเรียกเก็บ',
      render: (text, data) => <a>{currency(data.raw_data.expectedDeduction).format({ symbol: '', separator: ',', precision: 2 })}</a>,
    },
    {
      title: 'ยอดหักเงินเดือน',
      render: (text, data) => <a>{currency((+data.actual_lender) + (+data.actual_noburo)).format({ symbol: '', separator: ',', precision: 2 })}</a>,
    },
    {
      title: 'โอนให้สถาบันการเงิน',
      dataIndex: 'actual_lender',
      key: 'actual_lender',
      render: (text) => <a>{currency(text).format({ symbol: '', separator: ',', precision: 2 })}</a>,
    },
    {
      title: 'โอนให้ noburo',
      dataIndex: 'actual_noburo',
      key: 'actual_noburo',
      render: (text) => <a>{currency(text).format({ symbol: '', separator: ',', precision: 2 })}</a>,
    },
  ]

  useEffect(() => {
    setLenderReceived(data ? data.total_actual_lender : 0)
    setNoburoReceived(data ? data.total_actual_noburo : 0)
  }, [data]);

  return <>
    <CardListStyle>
      <CardStyle>
        <Statistic title="รวมยอดเรียกเก็บ" value={data ? data.total_expected_deduction : 0} precision={2} />
      </CardStyle>
      <CardStyle>
        <Statistic title="รวมยอดหักเงินเดือน" value={data ? data.total_actual_deduction : 0} precision={2} />
      </CardStyle>
     <CardStyle>
       <Statistic title="รวมโอนให้สถาบันการเงิน" value={data ? data.total_actual_lender : 0} precision={2} />
     </CardStyle>
      <CardStyle>
        <Statistic title="รวมโอนให้ noburo" value={data ? data.total_actual_noburo : 0} precision={2} />
      </CardStyle>
    </CardListStyle>
    <CustomTable searchPlaceholder={`ค้นหาโดย ชื่อ นามสกุล เลขบัตรประชาชน`} columns={columns} loading={loading} data={data ? data.invoice_summaries : []} total={data ? data.total_pages : 0}
                 currentPage={currentPage}
                 setCurrentPage={setCurrentPage} setSearch={setSearch}/>
  </>
}

export default SummaryTable
