import React from 'react';
import {Container, Header} from "./style";

const Layout = (props) => {
    const { children } = props;
    return (
      <Container>
        {children}
      </Container>
    );
}

export default Layout
